import React, { useState } from "react";
import "./Section.css";
import ItemCard from "../ItemCard/ItemCard";
import { useTranslation } from 'react-i18next';
import '../../Pages/LanguageSelector/LanguageSelector';
import { capitalizeSentence } from "../../helpers/utils";

function Section({  Cart  ,handleOpenItem ,category } ) {
  const { t, i18n } = useTranslation();
  return (
    <div className="SectionContainer" id={category.name}>
      <div className="SectionName">
      <div style={{color: '#AF8E4B',fontSize: 20,fontFamily: `${i18n.language}"etihadBook"`,fontWeight: '600',wordWrap: 'break-word'}}>
        {capitalizeSentence(new Map(JSON.parse(category.multiLanguages))?.get(`${i18n.language}-${"name"}`)??category.name)}
        </div>
      </div>

      <div className="ItemsContainer"  >
        {category.menuItems?.map((item, index) => (
          category.guestOrder? <ItemCard key={index} item={item} Cart={Cart} prepId={category.preparationAreaID} handleOpenItem={handleOpenItem}/> :
           <ItemCard key={index} item={item} Cart={Cart} prepId={category.preparationAreaID} canOrder={false} handleOpenItem={handleOpenItem}/>
        ))}
      </div>
    </div>
  );
}

export default Section;
