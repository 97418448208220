import { Divider, List, ListItem, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header.tsx";
import SearchBar from "../../Components/SearchBar/SearchBar";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const languages = [
  { value: "en", label: "English" },
  { value: "de", label: "German" },
  { value: "fr", label: "French" },
  { value: "ar", label: "Arabic" },
];

const style = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  bgcolor: "background.paper",
};

export default function LanguagePage() {
  const { t, i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [open, setOpen] = useState(false);
  const [languagesList, setLanguagesList] = useState(languages);
  const encryptedID = useSelector((state) => state.table.encryptedID);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleChange = (language) => {
    setSelectedLanguage(language);
    Cookies.set("language", language, { expires: 1 });
    if (language === "ar" || language === "he") {
      setIsRTL(true);
    } else {
      setIsRTL(false);
    }
    i18n.changeLanguage(language).then(() => {
      navigate(`/${encryptedID}`);
    });
    handleClose();
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language");
    if (selectedLanguage === "ar" || selectedLanguage === "he") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [i18n.language]);

  const onSearch = (value) => {
    const filteredLanguages = languages.filter((lang) =>
      lang.label.toLowerCase().includes(value.toLowerCase())
    );
    setLanguagesList(filteredLanguages);
  };

  return (
    <>
      <Header Header={t("Languages")} addnew={false} setOpenForm={""} />
      <h3>{t("ChooseLanguage")}:</h3>
      <SearchBar onSearch={onSearch} placeholder={t("SearchLanguages")} />

      <div className="Languages">
        {
          <List
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              bgcolor: "background.paper",
              boxShadow: "0 1px 6px rgb(32 33 36 / 28%)",
            }}
            component="nav"
            aria-label="mailbox folders"
          >
            {languagesList.map((lang) => (
              <>
                <ListItem
                  button
                  onClick={() => handleChange(lang.value)}
                  style={{
                    backgroundColor:
                      selectedLanguage === lang.value ? "#eee6d2" : "white",
                  }}
                >
                  <ListItemText primary={t(lang.label)} />
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        }
      </div>
    </>
  );
}
