import { EndPoints ,OrderStatus} from "../Constants/Enums";

async function cancelOnlineOrder(order) {
  try {
    const operationId = 17;
    const status=OrderStatus.cancelled
    const orderID=order.id
    const version=order._version
    const requestBody = {
      operationId,
      status,
      orderID,
      version
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(EndPoints.AppOperationsLambda, options);
    const jsonResponse = await response.json();
    // alert('Thank you, Your order is created successfully')
    return jsonResponse;
  } catch (error) {
    console.error("Error cancelling your order :", error);
    throw error;
  }
}

export default cancelOnlineOrder;
