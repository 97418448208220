import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "English",
  lng: "English",
  resources: {
    English: {
      translations: require("./locales/en/translations.json"),
    },
    Arabic: {
      translations: require("./locales/ar/translations.json"),
    },
    French: {
      translations: require("./locales/fr/translations.json"),
    },
    German: {
      translations: require("./locales/de/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["English", "Arabic", "French", "German"];

export default i18n;
