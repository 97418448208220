import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import OrderDetails from "./Pages/OrderDetails/OrderDetails";
import Cart from "./Pages/Cart/Cart";
import Languages from "./Pages/Languages/Languages";
import MyOrders from "./Pages/MyOrder/MyOrders";
import { useState } from "react";
import Review from "./Pages/Review/Review";
import Guard from "./Pages/Guard/Guard";
import { useTranslation } from "react-i18next";
import LanguagePage from "./Pages/Languages/LanguagePage";
import OrderTracking from "./Pages/OrderTracking/OrderTracking";
import { removeCachedData } from "./Services/getCachedData";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import MainSnackbar from "./Components/UI/MainSnackbar/MainSnackbar";
import SubCategories from "./Pages/SubCategories/SubCategories";
import MenuList from "./Pages/MenuList/MenuList";
import PdfView from "./Components/PdfView/PdfView";

function App() {
  const [isMainSelected, setisMainSelected] = useState(false);
  const [selectedMain, setSelectedMain] = useState();
  const { i18n } = useTranslation();

  const table = useSelector((state) => state.table.listing);
  const snackBarOpen = useSelector((state) => state.app.snackBarOpen);

  const handleIsMainSelected = () => {
    setisMainSelected(true);
  };

  const handleSelectedMain = (main) => {
    setSelectedMain(main);
  };

  useEffect(() => {
    removeCachedData();

    const selectedLanguage = Cookies.get("language");
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }

    const beforeUnloadHandler = (event) => {
      console.log("cleared");
      removeCachedData();
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);

  return (
    <>
      <div className="App">
        <Routes>
          <Route
            path={"/:id"}
            element={
              <Home
                isMainSelected={isMainSelected}
                handleSelectedMain={handleSelectedMain}
                handleIsMainSelected={handleIsMainSelected}
              />
            }
          />
          <Route
            exact
            path="/"
            element={
              <Home
                isMainSelected={isMainSelected}
                handleSelectedMain={handleSelectedMain}
                handleIsMainSelected={handleIsMainSelected}
              />
            }
          />
          <Route
            path="/subCategories"
            element={table ? <SubCategories /> : <Guard />}
          />
          <Route path="/menuList" element={table ? <MenuList /> : <Guard />} />

          <Route
            path="/orderdetails"
            element={table ? <OrderDetails /> : <Guard />}
          />
          <Route path="/cart" element={table ? <Cart /> : <Guard />} />
          <Route
            path="/languages"
            element={table ? <Languages /> : <Guard />}
          />
          <Route path="/myorders" element={table ? <MyOrders /> : <Guard />} />
          <Route path="/review" element={table ? <Review /> : <Guard />} />
          <Route path="/lang" element={table ? <LanguagePage /> : <Guard />} />
          <Route
            path="/trackOrder"
            element={table ? <OrderTracking /> : <Guard />}
          />
          <Route
            path="/pdfview"
            element={table ? <PdfView /> : <Guard />}
          />
        </Routes>

        {snackBarOpen && <MainSnackbar />}
      </div>
    </>
  );
}

export default App;
