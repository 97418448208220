import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "../src/i18n/config";
import { RTLProvider } from "./rtl";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter} from "react-router-dom";
import config from "./aws-exports";
import { Amplify } from "aws-amplify";

Amplify.configure(config);


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <RTLProvider locale="en">
      <Provider store={store}>
        <App />
      </Provider>
    </RTLProvider>
  </BrowserRouter>
);
