// actions
const SetMainView = "config/SetMainView";
const SetMainOrder = "config/SetMainOrder";
const SetSubView = "config/SetSubView";
const SetSubOrder = "config/SetSubOrder";
const SetCategoryView = "config/SetCategoryView";
const SetCategoryOrder = "config/SetCategoryOrder";

const DEFAULT_STATE = {
  mainCategoryView: true,
  mainCategoryOrder: true,
  subCategoryView: true,
  subCategoryOrder: true,
  categoryView: true,
  categoryOrder: true,
};

// reducer
const reducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case SetMainView:
      return Object.assign({}, state, { mainCategoryView: action.listing });
    case SetMainOrder:
      return Object.assign({}, state, { mainCategoryOrder: action.listing });
    case SetSubOrder:
      return Object.assign({}, state, { subCategoryOrder: action.listing });
    case SetCategoryOrder:
      return Object.assign({}, state, { categoryOrder: action.listing });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setMainView = (listing) => ({ listing, type: SetMainView });
export const setMainOrder = (listing) => ({ listing, type: SetMainOrder });
export const setSubOrder = (listing) => ({ listing, type: SetSubOrder });
export const setCategoryOrder = (listing) => ({listing, type: SetCategoryOrder});
