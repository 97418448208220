// actions
const SELECTED_MAIN = "app/SELECTED_MAIN";
const SELECTED_SUB_CATEGORY = "app/SELECTED_SUB_CATEGORY";
const SELECTED_CATEGORY = "app/SELECTED_CATEGORY";

const CURRENT_ORDER = "app/CURRENT_ORDER";

const OPEN_SNACKBAR = "app/OPEN_SNACKBAR";
const CLOSE_SNACKBAR = "app/CLOSE_MODAL";

const SHOW_SPA_OPTIONS = "app/SHOW_SPA_OPTIONS";

const DEFAULT_STATE = {
  mainSelected: null,

  snackBarOpen: false,
  snackBarContent: null,
  snackBarSeverity: "info",
  snackBarPersist: false,

  selectedMainCategory: null,
  selectedSubCategory: null,
  selectedCategory: null,

  currentOrder: null,

  showSpaOptions: false,
};

// reducer
const reducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: true,
        snackBarContent: action.snackBarContent,
        snackBarSeverity: action.snackBarSeverity,
        snackBarPersist: action.persist ? action.persist : false,
      });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: false,
        snackBarContent: null,
        snackBarSeverity: "info",
      });

    case SELECTED_MAIN:
      return Object.assign({}, state, {
        selectedMainCategory: action.selectedMainCategory,
      });

    case SELECTED_SUB_CATEGORY:
      return Object.assign({}, state, {
        selectedSubCategory: action.selectedSubCategory,
      });

    case SELECTED_CATEGORY:
      return Object.assign({}, state, {
        selectedCategory: action.selectedCategory,
      });

    case CURRENT_ORDER:
      return Object.assign({}, state, {
        currentOrder: action.currentOrder,
      });

    case SHOW_SPA_OPTIONS:
      return Object.assign({}, state, {
        showSpaOptions: action.showSpaOptions,
      });

    default:
      return state;
  }
};

export default reducer;

// action creators
export const openSnackBar = (snackBarContent, snackBarSeverity, persist) => ({
  snackBarContent,
  snackBarSeverity,
  persist,
  type: OPEN_SNACKBAR,
});

export const closeSnackBar = () => ({
  snackBarContent: null,
  type: CLOSE_SNACKBAR,
});

export const setSelectedMainCategory = (selectedMainCategory) => ({
  selectedMainCategory,
  type: SELECTED_MAIN,
});

export const setSelectedSubCategory = (selectedSubCategory) => ({
  selectedSubCategory,
  type: SELECTED_SUB_CATEGORY,
});

export const setSelectedCategory = (selectedCategory) => ({
  selectedCategory,
  type: SELECTED_CATEGORY,
});

export const setCurrentOrder = (currentOrder) => ({
  currentOrder,
  type: CURRENT_ORDER,
});

export const setShowSpaOptions = (showSpaOptions) => ({
  showSpaOptions,
  type: SHOW_SPA_OPTIONS,
});
