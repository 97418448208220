import { useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
  Grid,
  Button
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import updateCart from "../../Services/updateCart";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../store/ducks/cart";
import { Keys } from "../../Constants/Enums";
import { cacheData } from "../../Services/getCachedData";
import CommentIcon from "@mui/icons-material/Comment";
import Cookies from "js-cookie";
import { capitalizeSentence ,numberTranslation } from "../../helpers/utils";
import "./CartItem.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


function CartItem({
  ordereditem,
  total,
  itemOnly,
  setTotal,
  cartPrice,
  // updateNotes,
  // Cart,
  // notes,
  //setCartData,
  // handleUpdateCart
  // handleRemoveItem
}) {
  const navigate = useNavigate();
  const [orderQuantity, setOrderQuantity] = useState(ordereditem.quantity);
  const [itemTotal, setItemTotal] = useState(total);
  const [isLoadingplus, setIsLoadingplus] = useState(false);
  const [isLoadingminus, setIsLoadingminus] = useState(false);
  const [note, setNote] = useState(
    ordereditem.notes.length > 0 ? ordereditem.notes : ""
  );
  const [imageNotFound, setImageNotFound] = useState(false);
  const [disable, setDisable] = useState(false);
  // const Cart = useSelector((state) => state.cart.listing);
  const dispatch = useDispatch();
  const Cart = JSON.parse(Cookies.get("cart"));

  const concept = useSelector((state) => state.concept.listing);
  const encryptedID = useSelector((state) => state.table.encryptedID);

  const { t, i18n } = useTranslation();

  const handleDecrement = async () => {
    const newQuantity = ordereditem.quantity - 1;
    setOrderQuantity(newQuantity);
    setTotal(cartPrice - itemOnly);
    setDisable(true);
    if (newQuantity <= 0) {
      setTotal(cartPrice - itemOnly);
      for (let i = 0; i < Cart.orderedItems.length; i++) {
        delete Cart.orderedItems[i].__typename;
      }

      const updatedOrderedItems = Cart.orderedItems.filter((item) => {
        return !(
          item.menuItemID === ordereditem.menuItemID &&
          item.symphonyID === ordereditem.symphonyID &&
          item.choiceIDs.join(",") === ordereditem.choiceIDs.join(",") &&
          item.extraChoiceIDs.join(",") ===
            ordereditem.extraChoiceIDs.join(",") &&
          item.notes === ordereditem.notes &&
          item.name === ordereditem.name
        );
      });
      const cartID = Cart.id;
      const conceptID = concept.id;
      const version = Cart._version;
      const newOrderedItems = updatedOrderedItems;
      const totalPrice = cartPrice - itemOnly > 0 ? cartPrice - itemOnly : 0;
      const newCart = await updateCart(
        cartID,
        conceptID,
        version,
        newOrderedItems,
        totalPrice
      );
      dispatch(setCart(newCart));
      Cookies.set("cart", JSON.stringify(newCart));

      await cacheData(Keys.CART, newCart);

      if (newOrderedItems.length === 0) {
        navigate(`/${encryptedID}`);
      }
      setDisable(false);
    } else {
      setDisable(true);

      for (let i = 0; i < Cart.orderedItems.length; i++) {
        delete Cart.orderedItems[i].__typename;
      }
      const itemIndex = Cart.orderedItems.findIndex((item) => {
        return (
          item.menuItemID === ordereditem.menuItemID &&
          item.symphonyID === ordereditem.symphonyID &&
          item.choiceIDs.join(",") === ordereditem.choiceIDs.join(",") &&
          item.extraChoiceIDs.join(",") ===
            ordereditem.extraChoiceIDs.join(",") &&
          item.notes === ordereditem.notes &&
          item.name === ordereditem.name
        );
      });

      const updatedOrderedItems = [...Cart.orderedItems];
      if (itemIndex !== -1) {
        updatedOrderedItems[itemIndex] = {
          ...updatedOrderedItems[itemIndex],
          quantity: newQuantity,
          // price: itemTotal - itemOnly,
        };
        const cartID = Cart.id;
        const conceptID = concept.id;
        const version = Cart._version;
        const newOrderedItems = updatedOrderedItems;
        const totalPrice = cartPrice - itemOnly;
        const newCart = await updateCart(
          cartID,
          conceptID,
          version,
          newOrderedItems,
          totalPrice
        );
        dispatch(setCart(newCart));
        Cookies.set("cart", JSON.stringify(newCart));

        await cacheData(Keys.CART, newCart);
      }
      setDisable(false);
      // setIsLoadingminus(false);
    }
  };
  const handleIncrement = async () => {
    setOrderQuantity(orderQuantity + 1);
    setTotal(cartPrice + itemOnly);
    setDisable(true);
    // setIsLoadingplus(true)
    for (let i = 0; i < Cart.orderedItems.length; i++) {
      delete Cart.orderedItems[i].__typename;
    }
    const itemIndex = Cart.orderedItems.findIndex((item) => {
      return (
        item.menuItemID === ordereditem.menuItemID &&
        item.symphonyID === ordereditem.symphonyID &&
        item.choiceIDs.join(",") === ordereditem.choiceIDs.join(",") &&
        item.extraChoiceIDs.join(",") ===
          ordereditem.extraChoiceIDs.join(",") &&
        item.notes === ordereditem.notes &&
        item.name === ordereditem.name
      );
    });
    if (itemIndex !== -1) {
      const updatedOrderedItems = [...Cart.orderedItems];

      updatedOrderedItems[itemIndex] = {
        ...updatedOrderedItems[itemIndex],
        quantity: orderQuantity + 1,
        // price: itemTotal + itemOnly,
      };
      const cartID = Cart.id;
      const conceptID = concept.id;
      const version = Cart._version;
      const newOrderedItems = updatedOrderedItems;
      const totalPrice = cartPrice + itemOnly;
      const newCart = await updateCart(
        cartID,
        conceptID,
        version,
        newOrderedItems,
        totalPrice
      );
      dispatch(setCart(newCart));
      Cookies.set("cart", JSON.stringify(newCart));

      await cacheData(Keys.CART, newCart);
    }
    setDisable(false);
    // setIsLoadingplus(false);
  };

  const updateNotes = async () => {
    for (let i = 0; i < Cart.orderedItems.length; i++) {
      delete Cart.orderedItems[i].__typename;
    }
    const updatedOrderedItems = [...Cart.orderedItems];
    const itemIndex = Cart.orderedItems.findIndex((item) => {
      return (
        item.menuItemID === ordereditem.menuItemID &&
        item.symphonyID === ordereditem.symphonyID &&
        item.choiceIDs.join(",") === ordereditem.choiceIDs.join(",") &&
        item.extraChoiceIDs.join(",") ===
          ordereditem.extraChoiceIDs.join(",") &&
        item.notes === ordereditem.notes &&
        item.name === ordereditem.name
      );
    });

    updatedOrderedItems[itemIndex] = {
      ...updatedOrderedItems[itemIndex],
      notes: note,
      // price: itemTotal - itemOnly,
    };

    const cartID = Cart.id;
    const conceptID = concept.id;
    const newOrderedItems = updatedOrderedItems;
    const version = Cart._version;
    const totalPrice = Cart.totalPrice;
    const newCart = await updateCart(
      cartID,
      conceptID,
      version,
      newOrderedItems,
      totalPrice
    );
    dispatch(setCart(newCart));
    Cookies.set("cart", JSON.stringify(newCart));
  };
  const quantityStyle = {
    borderRadius: "2px",
    background: "linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%)",
    transition: "background-color 0.2s ease-in-out",
    color:"white",
    "&:hover": {
      backgroundColor: "linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%)",
    },
    boxShadow: "0px 4px 8px 2px rgba(0, 0, 0, 0.1)",
  };  
  return (
    <div style={{ width: "95%" }}>
      <div className="Items">
        <div className="ItemInfo">
          <div  className="Information">
          <Grid container  > 
          <Grid item xs={11} container alignContent={"center"} > 
          <Grid item xs={12}>
          <Typography style={{ display:"flex", fontWeight: "600" ,color: 'black',fontSize: 18,fontFamily:  `etihadBold${i18n.language}`,wordWrap: 'break-word'}}>
          {capitalizeSentence(new Map(JSON.parse(ordereditem.nameMultiLanguages))?.get(`${i18n.language}-${"name"}`)??ordereditem.name)}
          </Typography>
          </Grid>
            {ordereditem.choiceNames.length > 0 && (
              <div>
               <div className="line"></div>
              <Grid container direction={"row"} >
              <Typography style={{color: '#919191', fontSize: 15, fontFamily: `etihadBook${i18n.language}`, fontWeight: '600', marginRight:".2rem",marginLeft:".2rem" ,wordWrap: 'break-word'}}>{t("Contains")}: </Typography>
                {ordereditem.multiLanguages.map((multiLanguage, index) => {
                   return( 
                   <div key={index} style={{fontSize:13 ,display:"flex",alignContent:"center",wordBreak:"break-all" ,whiteSpace:"nowrap" ,fontFamily:`${i18n.language}"etihadBook"`}} className="items" > 
                   {(index===(ordereditem.multiLanguages.length-1))
                   ?capitalizeSentence(new Map(JSON.parse(multiLanguage))?.get(`${i18n.language}-${"name"}`)??ordereditem.choiceNames[index])
                   :capitalizeSentence(new Map(JSON.parse(multiLanguage))?.get(`${i18n.language}-${"name"}`)??ordereditem.choiceNames[index])
                   +","} 
                   </div>)
                })}
              </Grid>
              </div>
            )}
            </Grid>
            <Grid item xs={1} container 
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    justifyItems:"center",
                    justifySelf:"center",
                    borderRadius: "2px",
                    backgroundColor: "white",
                    transition: "background-color 0.2s ease-in-out",
                    boxShadow: "0px 4px 8px 2px rgba(0, 0, 0, 0.1)",
                  }}
                >
                <Grid item xs={12} container justifyContent={"center"} justifyItems={"center"} >
                  <Button
                    sx={ {color:disable?"gray":"#D2B56F"}}
                    onClick={handleIncrement}
                    disabled={disable}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                  </Grid>
                  <Grid item xs={12} container justifyContent={"center"} justifyItems={"center"} sx={quantityStyle}>
                  <Typography align="center" >
                    {numberTranslation(ordereditem.quantity,i18n.language)}
                  </Typography>
                  </Grid>
                  <Grid item xs={12} maxHeight="40px"  container justifyContent={"center"} justifyItems={"center"}>
                  <Button
                    sx={ {color:disable?"gray":"#D2B56F"}}
                    onClick={handleDecrement}
                    disabled={disable}
                  >
                    <RemoveIcon fontSize="small"/>
                  </Button>
                  </Grid>
                </Grid>
                {note&&(<div><span style={{color: 'rgba(0, 0, 0, 0.94)', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>{t("Notes")}:</span>
                <span style={{color: 'rgba(0, 0, 0, 0.45)', fontSize: 15, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>
                {note}{" "}</span></div>)}
              {itemTotal > 0 && (
                <div className="ItemPrice">
                  {t("AED")} {itemTotal * orderQuantity}
                </div>
              )}
            </Grid>
           
            {/* <TextField
              placeholder={ordereditem.notes ? ordereditem.notes : t("Notes")}
              style={{
                borderRadius: "50px",
                width: "100%",
                margin: "1rem auto",
              }}
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
              onBlur={updateNotes}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CommentIcon />
                  </InputAdornment>
                ),
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
