import { EndPoints } from "../Constants/Enums";
import Cookies from 'js-cookie';

async function updateCallWaiter(callWaiterId,tableID,count, version) {
    try {
      const operationId = 14;
      const userID = JSON.parse( Cookies.get('userCookie')).uid
      const requestBody = {
        operationId,
        callWaiterId,
        userID: userID,
        tableID,
        count,
        version,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };
      const response = await fetch(EndPoints.AppOperationsLambda, options);
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.error("Error creating Cart :", error);
      throw error;
    }
  }
  
  export default updateCallWaiter;
  