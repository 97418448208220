import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function DialogAlert({ handleClose, open, text, title }) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <span className="title3">{title}</span>

          <DialogContentText sx={{ color: "black", fontSize: "medium", marginTop:'0.5rem' }}>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} sx={{ color: "#c5951bdd" }}>
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
