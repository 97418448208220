// actions
const CALL_WAITER = "callWaiter/CALL_WAITER";
const CALL_WAITER_FLAG = "callWaiter/CALL_WAITER_FLAG";

const DEFAULT_STATE = {
  callWaiter: null,
  callWaiterFlag: false,
};

// reducer
const reducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case CALL_WAITER:
      return Object.assign({}, state, { callWaiter: action.callWaiter });
    case CALL_WAITER_FLAG:
      return Object.assign({}, state, {
        callWaiterFlag: action.callWaiterFlag,
      });

    default:
      return state;
  }
};

export default reducer;

// action creators
export const setCallWaiter = (callWaiter) => ({
  callWaiter,
  type: CALL_WAITER,
});

export const setCallWaiterFlag = (callWaiterFlag) => ({
  callWaiterFlag,
  type: CALL_WAITER_FLAG,
});
