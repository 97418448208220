import { Typography, IconButton, TextField, Grid, Button } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./OrderDetails.css";
import Choises from "../../Components/Choices/Choises";
import updateCart from "../../Services/updateCart";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setCart, setQuantity } from "../../store/ducks/cart";
import { cacheData } from "../../Services/getCachedData";
import { Keys } from "../../Constants/Enums";
import DialogAlert from "../../Components/Dialog/DialogAlert";
import createCart from "../../Services/createCart";
import Cookies from "js-cookie";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  capitalizeSentence,
  numberTranslation,
  getTimeInUAE,
} from "../../helpers/utils";

function OrderDetails({ item, cart, prepId, handleCloseItemCard }) {
  const order = item;
  const [clicked, setClicked] = useState(false);
  const price =
    order?.prices[0]?.price !== undefined ? order.prices[0].price : "";
  const navigate = useNavigate();
  const [itemCount, setItemCount] = useState(1);
  const [notes, setNotes] = useState("");
  const [selectedChoicesPrice, setSelectedChoicesPrice] = useState(0);
  const [isContainerDisabled, setIsContainerDisabled] = useState(false);
  const { t, i18n } = useTranslation();
  const [selectedChoiceIds, setSelectedChoiceIds] = useState([]);
  const [selectedChoiceNames, setSelectedChoiceNames] = useState([]);
  const [choicesMultiLanguages, setChoicesMultiLanguages] = useState([]);

  const [missingChoiceGroup, setMissingChoiceGroup] = useState(null);

  const mainOrder = useSelector((state) => state.configs.mainCategoryOrder);
  const subOrder = useSelector((state) => state.configs.subCategoryOrder);
  // const categoryOrder = canOrder;
  const table = useSelector((state) => state.table.listing);
  const concept = useSelector((state) => state.concept.listing);
  const [btn, setBtn] = useState(true);
  // const canOrder = mainOrder && subOrder && categoryOrder
  const canOrder = true;
  const total =
    itemCount *
    (price +
      Object.values(selectedChoicesPrice).reduce((sum, val) => sum + val, 0));
  const handleIncrement = () => {
    setItemCount((prevCount) => prevCount + 1);
  };
  const dispatch = useDispatch();

  const total_without_item_count =
    price +
    Object.values(selectedChoicesPrice).reduce((sum, val) => sum + val, 0);

  const handleDecrement = () => {
    if (itemCount > 1) {
      setItemCount((prevCount) => prevCount - 1);
    }
  };

  const createNewCart = useCallback(async () => {
    const newCart = await createCart(table.conceptID, [], 0, getTimeInUAE());
    setBtn(true);
    dispatch(setCart(newCart));
    Cookies.set("cart", JSON.stringify(newCart), { expires: 1 });
  }, []);

  useEffect(() => {
    if (canOrder) {
      const cookieData = {
        uid: JSON.parse(Cookies.get("userCookie")).uid, // Example UID
        hasCart: true, // Example username
        canReview: JSON.parse(Cookies.get("userCookie")).canReview,
      };
      Cookies.set("userCookie", cookieData, { expires: 1 });
      if (!Cookies.get("cart")) {
        setBtn(false);
        createNewCart();
      }
    }
  }, [createNewCart]);

  const Cart = useSelector((state) => state.cart.listing);

  const handleAddtoBasket = async () => {
    try {
      let missingChoicesMessage = "";
      for (const choiceGroup of order?.choiceGroups) {
        if (choiceGroup.required) {
          const selectedGroupChoices = selectedChoiceIds?.filter((id) =>
            choiceGroup.choices.some((choice) => choice.id === id)
          );
          if (selectedGroupChoices?.length < choiceGroup.minNumberOfChoices) {
            // missingChoicesMessage += `You should choose at least ${choiceGroup.minNumberOfChoices} from ${choiceGroup.name}\n`;
            setMissingChoiceGroup(choiceGroup);
            setClicked(true);
            return;
          }
        }
      }
      if (missingChoicesMessage) {
        // alert(missingChoicesMessage);
        setClicked(true);
        return;
      } else {
        setIsContainerDisabled(true);
        for (let i = 0; i < Cart && Cart?.orderedItems.length; i++) {
          delete Cart.orderedItems[i].__typename;
        }
        const addedOrder = {
          menuItemID: order?.id,
          symphonyID: order?.symphonyID,
          name: order?.name,
          nameMultiLanguages: order?.multiLanguages,
          quantity: itemCount,
          choiceIDs: selectedChoiceIds,
          choiceNames: selectedChoiceNames,
          choiceSymphonyID: [],
          extraChoiceIDs: [],
          preparationAreaID: prepId,
          extraChoiceSymphonyID: [],
          price: parseFloat(total_without_item_count),
          notes: notes,
          image: order?.image,
          multiLanguages: choicesMultiLanguages,
        };

        const cartID = Cart && Cart.id;
        const version = Cart && Cart._version;
        const existingOrder =
          Cart &&
          Cart.orderedItems?.filter((item) => {
            return (
              item.menuItemID === addedOrder.menuItemID &&
              item.symphonyID === addedOrder.symphonyID &&
              item.choiceIDs.join(",") === addedOrder.choiceIDs.join(",") &&
              item.extraChoiceIDs.join(",") ===
                addedOrder.extraChoiceIDs.join(",") &&
              item.notes === addedOrder.notes &&
              item.name === addedOrder.name
              // item.choiceSymphonyID === addedOrder.choiceSymphonyID &&
              // item.extraChoiceSymphonyID === addedOrder.extraChoiceSymphonyID &&
            );
          });

        if (existingOrder?.length > 0) {
          // if item is found then update its quantity and price
          const updatedOrderedItems = Cart.orderedItems.map((item) => {
            if (
              item.menuItemID === addedOrder.menuItemID &&
              item.symphonyID === addedOrder.symphonyID &&
              item.choiceIDs.join(",") === addedOrder.choiceIDs.join(",") &&
              item.extraChoiceIDs.join(",") ===
                addedOrder.extraChoiceIDs.join(",") &&
              item.notes === addedOrder.notes &&
              item.name === addedOrder.name
              // item.choiceSymphonyID === addedOrder.choiceSymphonyID &&
              // item.extraChoiceSymphonyID === addedOrder.extraChoiceSymphonyID &&
            ) {
              // Update the item's quantity and price
              return {
                ...item,
                quantity: item.quantity + itemCount,
                // price:  addedOrder.price,
              };
            }
            return item;
          });
          for (let i = 0; i < updatedOrderedItems.length; i++) {
            delete updatedOrderedItems[i].__typename;
          }

          const totalPrice = Cart.totalPrice + total;
          const conceptID = concept.id;
          const updated = await updateCart(
            cartID,
            conceptID,
            version,
            updatedOrderedItems,
            totalPrice
          );
          dispatch(setCart(updated));
          Cookies.set("cart", JSON.stringify(updated));
          await cacheData(Keys.CART, updated);
          const totalQuantity = updated.orderedItems.reduce(
            (total, orderedItem) => total + orderedItem.quantity,
            0
          );
          dispatch(setQuantity(totalQuantity));
        } else {
          // add new item to the cart
          const newOrderedItems = [...Cart.orderedItems, addedOrder];
          const totalPrice = Cart.totalPrice + total;
          const conceptID = concept.id;
          for (let i = 0; i < newOrderedItems.length; i++) {
            delete newOrderedItems[i].__typename;
          }
          const updated = await updateCart(
            cartID,
            conceptID,
            version,
            newOrderedItems,
            totalPrice
          );
          dispatch(setCart(updated));
          Cookies.set("cart", JSON.stringify(updated));
          await cacheData(Keys.CART, updated);
          const totalQuantity = updated.orderedItems.reduce(
            (total, orderedItem) => total + orderedItem.quantity,
            0
          );
          dispatch(setQuantity(totalQuantity));
        }
        handleOpen();
        setIsContainerDisabled(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectedChoices = (
    groupName,
    price,
    id,
    selectedNames,
    optionName,
    optionMultiLanguages
  ) => {
    //add selected nae to array of selectedChoiceNames
    if (!selectedChoiceIds.includes(id)) {
      // If not, add it
      setSelectedChoiceIds((prevIds) => [...prevIds, String(id)]);
      let list = [...selectedChoiceNames, ...selectedNames];
      setSelectedChoiceNames(Array.from(new Set(list)));

      let multiLanguagesList = [...choicesMultiLanguages, optionMultiLanguages];
      setChoicesMultiLanguages(Array.from(new Set(multiLanguagesList)));

      // setSelectedChoicesPrice((prevPrices) => ({
      //   ...prevPrices,
      //   [groupName]: price,
      // }));
    } else {
      // If it's already in the array, remove it
      setSelectedChoiceIds((prevIds) =>
        prevIds?.filter((choiceId) => choiceId !== String(id))
      );
      setSelectedChoiceNames((prevNames) =>
        prevNames?.filter((choiceName) => choiceName !== optionName)
      );
      setChoicesMultiLanguages((prevList) =>
        prevList?.filter(
          (multiLanguage) => multiLanguage !== optionMultiLanguages
        )
      );
    }
  };
  const [open, setOpen] = useState(false);
  const [imageNotFound, setImageNotFound] = useState(false);
  const encryptedID = useSelector((state) => state.table.encryptedID);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    navigate(`/${encryptedID}`);
  };
  const quantityStyle = {
    borderRadius: "2px",
    background: "linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%)",
    transition: "background-color 0.2s ease-in-out",
    color: "white",
    "&:hover": {
      backgroundColor: "linear-gradient(270deg, #AF8E4B 0%, #D2B56F 100%)",
    },
    boxShadow: "0px 4px 8px 2px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div>
      <div className="OrderDetailsContainer">
        <div className="InfoPrice">
          <Grid container>
            <Grid
              item
              xs={12}
              container
              justifyContent={"start"}
              justifyItems={"start"}
            >
              <IconButton aria-label="close" onClick={handleCloseItemCard}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid container className="OrderInfo">
              <Grid
                item
                xs={11}
                sx={{ paddingRight: ".5rem" }}
                container
                alignContent={"center"}
              >
                <Grid item xs={12}>
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 25,
                      fontFamily: `etihadBold${i18n.language}`,
                      wordWrap: "break-word",
                    }}
                  >
                    {capitalizeSentence(
                      new Map(JSON.parse(order?.multiLanguages))?.get(
                        `${i18n.language}-${"name"}`
                      ) ?? order?.name
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} marginTop={".5rem"}>
                  <Typography
                    style={{
                      color: "#919191",
                      fontSize: 15,
                      fontFamily: `${i18n.language}"etihadBook"`,
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                  >
                    {capitalizeSentence(
                      new Map(JSON.parse(order?.multiLanguages))?.get(
                        `${i18n.language}-${"description"}`
                      ) ?? order?.description
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                {canOrder && (
                  <>
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                        justifySelf: "center",
                        borderRadius: "2px",
                        backgroundColor: "white",
                        transition: "background-color 0.2s ease-in-out",
                        boxShadow: "0px 4px 8px 2px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent={"center"}
                        justifyItems={"center"}
                      >
                        <Button
                          sx={{ color: "#D2B56F" }}
                          onClick={handleIncrement}
                        >
                          <AddIcon fontSize="small" />
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent={"center"}
                        justifyItems={"center"}
                        sx={quantityStyle}
                      >
                        <Typography align="center">
                          {numberTranslation(itemCount, i18n.language)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        maxHeight="40px"
                        container
                        justifyContent={"center"}
                        justifyItems={"center"}
                      >
                        <Button
                          sx={{ color: "#D2B56F" }}
                          onClick={handleDecrement}
                        >
                          <RemoveIcon fontSize="small" />
                        </Button>
                      </Grid>
                    </Grid>

                    <div className="CounterContainer">
                      {total > 0 && (
                        <div className="Price">
                          {t("AED")} {total}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        {order?.choiceGroups.length > 0 && (
          <div style={{ marginTop: "1rem" }} className="line"></div>
        )}
        {canOrder && (
          <>
            <div className="Choices" style={{ margin: "1rem" }}>
              {order?.choiceGroups.map((choiceGroup, index) => {
                return (
                  <React.Fragment key={index}>
                    <Choises
                      key={index}
                      // SideInfo={`min: ${choiceGroup.minNumberOfChoices} - max: ${choiceGroup.maxNumberOfChoices}`}
                      options={choiceGroup.choices}
                      onSelectedChoices={(
                        price,
                        id,
                        selectedNames,
                        optionName,
                        optionMultiLanguages
                      ) =>
                        handleSelectedChoices(
                          choiceGroup.name,
                          price,
                          id,
                          selectedNames,
                          optionName,
                          optionMultiLanguages
                        )
                      }
                      choiceGroup={choiceGroup}
                    />
                  </React.Fragment>
                );
              })}
              {clicked && (
                <DialogAlert
                  handleClose={() => setClicked(false)}
                  open={clicked}
                  title={t("Missing Extra")}
                  text={
                    t("You should choose at least ") +
                    numberTranslation(
                      missingChoiceGroup.minNumberOfChoices,
                      i18n.language
                    ) +
                    t(" from ") +
                    capitalizeSentence(
                      new Map(
                        JSON.parse(missingChoiceGroup.multiLanguages)
                      )?.get(`${i18n.language}-${"name"}`) ?? item.name
                    )
                  }
                />
              )}
            </div>
            <div className="Notes">
              <TextField
                id="outlined-multiline-flexible"
                label={t("Notes")}
                multiline
                style={{ borderRadius: "50px" }}
                onChange={(event) => setNotes(event.target.value)}
                rows={4}
              />
            </div>
            {btn && (
              <div className="fixedContainer2">
                <div
                  className={`BlueContainer ${
                    isContainerDisabled ? "DisabledContainer" : ""
                  }`}
                  onClick={isContainerDisabled ? null : handleAddtoBasket}
                >
                  <div className="AddToBasketButton">
                    <Typography
                      fontSize={20}
                      fontFamily={`etihadBold${i18n.language}`}
                    >
                      {t("basketAdd")}
                    </Typography>
                  </div>
                  {total > 0 && (
                    <div className="PriceTypography">
                      <Typography>
                        {t("AED")} {total}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleCloseItemCard}
        >
          <Alert
            onClose={handleCloseItemCard}
            severity="success"
            sx={{
              position: "fixed", // Set position to 'fixed'
              top: "16px", // Adjust the top distance as needed
              right: "16px", // Adjust the right distance as needed
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseItemCard}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {t("itemAddSuccess")}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default OrderDetails;
