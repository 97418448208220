import { EndPoints } from "../Constants/Enums";

export function decryptID(encryptedID, key){
    const encrypted = atob(encryptedID); // Decode from base64
    let decrypted = '';
    for (let i = 0; i < encrypted.length; i++) {
      const encryptedChar = encrypted.charCodeAt(i);
      const keyChar = key.charCodeAt(i % key.length);
      const decryptedChar = encryptedChar ^ keyChar;
      decrypted += String.fromCharCode(decryptedChar);
    }
    return decrypted;
}

export async function validateTable(qrCode) {
    try {
      const operationId = 10;
      const requestBody = { operationId,qrCode };
      const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        };
        const response = await fetch(EndPoints.AppOperationsLambda, options);
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
      console.error('Error getting QR Code :', error);
      throw error;
    }
  }
  