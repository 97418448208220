import React, {useState} from "react";
import "./ReviewForm.css";
import RadioGroupRating from "../RatingEmojies/RatingEmojies";
import {
  TextField,
  InputAdornment,
  IconButton,
  Snackbar
} from "@mui/material";
import RateReviewIcon from '@mui/icons-material/RateReview';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';
import { useTranslation } from 'react-i18next';
import createReview from "../../Services/createReview";
import { getTimeInUAE } from "../../helpers/utils";


function ReviewForm({ onClose,orderNumber, order = true}) {
  const { t } = useTranslation(); 

  const [review, setReview] = useState()
  const [value, setValue] = useState('')

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const submitReview = async () => {
    const submit = await createReview(review, value ,getTimeInUAE())
    if(submit){
      handleOpen()
    }
  }
  return (
    <div className="form">
      <div className="info">
       {order && <span className="title2">Please enter your review</span>}
          <p className="description">
            {order? `Review for order number: ${orderNumber}` : "How was your experience?" }
          </p>
      </div>

      <div className="input-fields">
        {order? <RadioGroupRating/> :
         <Rating name="no-value" size='large' value={value} onChange={(event) => setValue(event.target.value)}/>}
      </div>

      {!order &&
          <div className="review">
          <TextField
            placeholder={t('Review')}
            style={{ borderRadius: "50px"}}
            value={review}
            onChange={(event) => setReview(event.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RateReviewIcon />
                </InputAdornment>
              ),
            }}
          />
          </div>
      }

      <div className="action-btns">
        <a className="verify" onClick={submitReview}>
          Submit
        </a>
        <a className="clear" onClick={onClose}>
          Cancel
        </a>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{
            position: 'fixed',
            top: '16px',
            right: '16px', 
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          Review Sent Successfully
        </Alert>
      </Snackbar>

    </div>
  );
}

export default ReviewForm;
