// actions
const SET = "cart/SET";
const QUANTITY = "cart/QUANTITY";
const TOTAL = "cart/TOTAL";

const DEFAULT_STATE = {
  listing: null,
  quantity: 0,
  total: 0,
};

// reducer
const reducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case QUANTITY:
      return Object.assign({}, state, { quantity: action.quantity });
    case TOTAL:
      return Object.assign({}, state, { total: action.total });

    default:
      return state;
  }
};

export default reducer;

// action creators
export const setCart = (listing) => ({ listing, type: SET });
export const setQuantity = (quantity) => ({ quantity, type: QUANTITY });
export const setTotal = (total) => ({ total, type: TOTAL });
