
export function formatDate(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const day = days[date.getUTCDay()];
  const dateNum = date.getUTCDate();
  const month = months[date.getUTCMonth()];

  return `${day},${dateNum} ${month}`;
}

// Format date object
export function formatTime(date) {
  const hours = String(date.getHours() % 12 || 12); // Convert 0 to 12
  const minutes = String(date.getMinutes());
  const seconds = String(date.getSeconds());
  return `${hours}:${minutes}:${seconds}`;
}

// Format date string
export function getHHMMSS(string) {
  const date2 = new Date(string);
  return date2.getHours() + ":" + date2.getMinutes() + ":" + date2.getSeconds();
}

// Format date object to YYYYMMDDHHMMSS
export function formatDateToYYYYMMDDHHMMSS(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}
export function getTimeInUAE() {
  const currentDate = new Date();
  const options = { timeZone: 'Asia/Dubai', hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const uaeDateTime = new Intl.DateTimeFormat('en-US', options).format(currentDate);
  const time = new Date(uaeDateTime)
  return time;
}

// number translation

export function numberTranslation(number,language) {
  if(language==="Arabic")
  {
  let stringNumber =""+number;
  let output="";
  for(let i=0;i<stringNumber.length;i++)
  {
    switch(stringNumber[i])
    {
      case "0":output+="٠";break;
      case "1":output+="١";break;
      case "2":output+="٢";break;
      case "3":output+="٣";break;
      case "4":output+="٤";break;
      case "5":output+="٥";break;
      case "6":output+="٦";break;
      case "7":output+="٧";break;
      case "8":output+="٨";break;
      case "9":output+="٩";break;
      default:output+=stringNumber[i];break;
    }
  }
  return output;
}
return number
}

/**
 * Capitalize first letter of a given string
 *
 * @param string string: string
 *
 * @returns string
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Capitalize Sentence

 * @param string : string
 *
 * @returns string
 */
export function capitalizeSentence(string) {
  let result = "";

  const words = string.split(" ");

  for (let word of words) {
    result +=
      result.length === 0
        ? capitalizeFirstLetter(word)
        : " " + capitalizeFirstLetter(word);
  }

  return result;
}
