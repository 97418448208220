import { useState } from "react";
import "./OrderCard.css";
import ReviewForm from "../ReviewForm/ReviewForm";
import TrackOrder from "../TrackOrder/TrackOrder";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "../../Constants/Enums";
import { getHHMMSS } from "../../helpers/utils";
import { setCurrentOrder } from "../../store/ducks/app";
import { useDispatch } from "react-redux";
import { Typography,Grid,Box } from "@mui/material";
import { capitalizeSentence , numberTranslation } from "../../helpers/utils";
import Cookies from "js-cookie";


function OrderCard({
  state,
  orderNumber,
  items,
  totalPrice,
  specialRequest,
  order,
  date,
  orderStatus,
}) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isTrackOpen, setIsTrackOpen] = useState(false);
  const [CartData, setCartData] = useState([]);

  const handleReviewClick = () => {
    setIsReviewOpen(true);
  };
  const closeReviewForm = () => {
    setIsReviewOpen(false);
  };

  function formatStatus(status) {
    switch (status) {
      case OrderStatus.acceptedByWaiter || OrderStatus.sentToKitchen: {
        return "placed";
      }
      case OrderStatus.delivered ||
        OrderStatus.ready ||
        OrderStatus.outForDelivery: {
        return "delivered";
      }
      default: {
        return "pending";
      }
    }
  }

  /*handle reorderClick*/

  const handleReorderClick = () => {
    setCartData(order);
    // Navigate to the cart page with the order data
    navigate("/cart", { state: { CartData: order } });
  };

  const handleTrackOrder = () => {
    dispatch(setCurrentOrder(order));
    Cookies.set("trackedOrder", JSON.stringify(order), { expires: 1 });
    navigate("/trackOrder", { state: { Order: order, BackTo: "myOrders" } });
  };

  const closeTrackForm = () => {
    setIsTrackOpen(false);
  };

  function formatOrderDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  return (
    <div className="master-container">
      {/* past orders */}
      {state === "past" ? (
        <div className="card cart">
        <div style={{display:"flex" , borderBottom:"1px solid #efeff3"}}>
        <label className="title">
        <Typography sx={{ fontFamily:`etihadBook${i18n.language}`,fontWeight:600,letterSpacing: 0.88,wordWrap: 'break-word'}}> 
            {t("currentOrderNum")}
            {orderNumber}
        </Typography>

        <Typography sx={{ fontFamily:`etihadBook${i18n.language}`,color: 'rgba(115.74, 113.33, 113.33, 0.73)',fontWeight:600,letterSpacing: 0.88,wordWrap: 'break-word'}}> 
            {t("Placed at")} 
            &nbsp;
            {numberTranslation(getHHMMSS(date),i18n.language)}
        </Typography>
        </label>
      
        {/* <div className="ReviewButtonContainer">
            <button className="button" style={{fontFamily:`${i18n.language}"etihadBook"`}} onClick={handleReorderClick}>
              {t("Reorder")}
            </button>

            <button className="button" onClick={handleReviewClick}>
              {t("orderRate")}
            </button>
          </div> */}
           </div>
           <div className="products">
            {items?.map((item, index) => (
              <div className="product" key={index}>
                <div className="OrderCardInfo">
                <Typography sx={{fontFamily: `etihadBold${i18n.language}`}} textAlign={"start"}> 
                {capitalizeSentence(new Map(JSON.parse(item.nameMultiLanguages))?.get(`${i18n.language}-${"name"}`)??item.name)}
                 </Typography>
                 <Typography sx={{ color:"#AF8E4B",fontFamily: `etihadBold${i18n.language}`}} textAlign={"center"}> 
                {i18n.language==="Arabic"?"":"x"}
                  {numberTranslation(item.quantity,i18n.language)}
                  {i18n.language!=="Arabic"?"":"x"}
                 </Typography>
                 <Grid>
               {item.multiLanguages.map((multiLanguage, index) => (
                      <Typography key={index} textAlign={"center"}>{"-"} &nbsp; 
                      {capitalizeSentence(new Map(JSON.parse(multiLanguage))?.get(`${i18n.language}-${"name"}`)??item.choiceNames[index])}
                      </Typography>
                    ))}
                    </Grid>
                </div>

                {/* {item.price>0 && <label className="price small">{item.price} {t('AED')}</label>} */}
              </div>
            ))}
          </div>
          {(totalPrice > 0 &&   
        <div className="checkout">
            <div className="details">
              { <span>{t("orderTotalPrice")}</span>}
           
                <span>
                  {totalPrice} {t("AED")}
                </span>
              
              {specialRequest?.length > 0 && (
                <>
                  <span>{t("orderSpecialRequest")}</span>
                  <span>{specialRequest}</span>
                </>
              )}
            </div>
          </div>)}

          {isReviewOpen && (
            <div className="overlay">
              <ReviewForm onClose={closeReviewForm} orderNumber={orderNumber} />
            </div>
          )}
        </div>
      ) : (
        // coming orders
        <div className="card cart">
          <div style={{display:"flex" , borderBottom:"1px solid #efeff3"}}>
          <label className="title">
          <Typography sx={{ fontFamily:`etihadBook${i18n.language}`,fontWeight:600,letterSpacing: 0.88,wordWrap: 'break-word'}}> 
              {t("currentOrderNum")}
              {orderNumber}
          </Typography>

          <Typography sx={{ fontFamily:`etihadBook${i18n.language}`,color: 'rgba(115.74, 113.33, 113.33, 0.73)',fontWeight:600,letterSpacing: 0.88,wordWrap: 'break-word'}}> 
            {t("Placed at")} 
            &nbsp;
            {numberTranslation(getHHMMSS(date),i18n.language)}
        </Typography>
          </label>
        
          {orderStatus !== OrderStatus.delivered &&
            orderStatus !== OrderStatus.ready &&
            orderStatus !== OrderStatus.outForDelivery && (
              <div className="ReviewButtonContainer">
                <button className="button" style={{fontFamily:`${i18n.language}"etihadBook"`}} onClick={handleTrackOrder}>
                  {t("orderTrack")}
                </button>
              </div>
            )}
             </div>
          
          <div className="products">
            {items?.map((item, index) => (
              <div className="product" key={index}>
                <div className="OrderCardInfo">
                <Typography sx={{fontFamily: `etihadBold${i18n.language}`}} textAlign={"start"}> 
                {capitalizeSentence(new Map(JSON.parse(item.nameMultiLanguages))?.get(`${i18n.language}-${"name"}`)??item.name)}
                 </Typography>
                <Typography sx={{ color:"#AF8E4B",fontFamily: `etihadBold${i18n.language}`}} textAlign={"center"}> 
                {i18n.language==="Arabic"?"":"x"}
                  {numberTranslation(item.quantity,i18n.language)}
                  {i18n.language!=="Arabic"?"":"x"}
                 </Typography>
                 <Grid>
                 {item.multiLanguages.map((multiLanguage, index) => (
                      <Typography  key={index} textAlign={"center"}>{"-"} &nbsp;
                      {capitalizeSentence(new Map(JSON.parse(multiLanguage))?.get(`${i18n.language}-${"name"}`)??item.choiceNames[index])}
                      </Typography>
                    ))}
                    </Grid>
                </div>

                {/* {item.price>0 && <label className="price small">{item.price} {t('AED')}</label>} */}
              </div>
            ))}
          </div>
        {(totalPrice > 0 &&   
        <div className="checkout">
            <div className="details">
              { <span>{t("orderTotalPrice")}</span>}
           
                <span>
                  {totalPrice} {t("AED")}
                </span>
              
              {specialRequest?.length > 0 && (
                <>
                  <span>{t("orderSpecialRequest")}</span>
                  <span>{specialRequest}</span>
                </>
              )}
            </div>
          </div>)}
          {isTrackOpen && (
            <div className="overlay">
              <TrackOrder
                onClose={closeTrackForm}
                orderNumber={orderNumber}
                order={order}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default OrderCard;
