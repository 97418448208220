// actions
const SET = "subCategories/SET";
const PDF = "subCategories/PDF";

const DEFAULT_STATE = {
  listing: [],
  pdf:null

};

// reducer
const reducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case PDF:
      return Object.assign({}, state, { pdf: action.pdf });
  

    default:
      return state;
  }
};

export default reducer;

// action creators
export const setSubCategories = (listing) => ({ listing, type: SET });
export const setPDF = (pdf) => ({ pdf, type: PDF });
