import React, { useEffect, useState } from "react";
import "./MyOrders.css";
import Header from "../../Components/Header/Header.tsx";
import { Tab, Tabs, Typography } from "@mui/material";
import OrderCard from "../../Components/OrderCard/OrderCard";
import getUserOrders from "../../Services/getUserOrders";
import MainLoader from "../../Components/MainLoader/MainLoader";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../Constants/Enums";
import Navbar from "../../Components/NavBar/NavBar";

function MyOrder() {
  const [value, setValue] = React.useState(0);
  const [MyOrders, setMyOrders] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchMenuItem = async () => {
      try {
        const data = await getUserOrders();
        setMyOrders(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchMenuItem();
  }, []);

  const pastOrders = MyOrders?.filter((order) => {
    return (
      (order.status === OrderStatus.delivered ||
        order.status === OrderStatus.cancelled) &&
      order.parentOrder === "none"
    );
  });

  const currentOrders = MyOrders?.filter((order) => {
    return (
      order.status !== OrderStatus.delivered &&
      order.status !== OrderStatus.cancelled &&
      order.parentOrder === "none"
    );
  });

  pastOrders?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  currentOrders?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  return (
    <>
      {isLoading ? (
        <div className="OrdersContainerLoading">
          <MainLoader />
        </div>
      ) : (
        <>
          {/* <Header Header={t("MyOrders")} addnew={false} setOpenForm={""} /> */}
          <div className="OrdersContainer">
            <div className="NavBarContainer">
              <Navbar items={[]} />
            </div>
            <Tabs
              style={{ marginTop: "4.5rem" }}
              value={value}
              onChange={handleChange}
              centered
            >
              <Tab
                label={t("currentOrders")}
                sx={{
                  "&.Mui-selected": {
                    color: "#C5941B", // Change the text color when the tab is selected
                  },
                  fontSize: "small",
                  textTransform: "capitalize",
                }}
              />
              <Tab
                label={t("pastOrders")}
                sx={{
                  "&.Mui-selected": {
                    color: "#C5941B", // Change the text color when the tab is selected
                  },
                  fontSize: "small",
                  textTransform: "capitalize",
                }}
              />
            </Tabs>
            <div style={{ marginTop: "4.5rem" }}></div>
            {value === 0 ? (
              <div className="AllOrders">
                {/* map here */}
                {currentOrders.length > 0 ? (
                  currentOrders.map((Order, index) => {
                    return (
                      <OrderCard
                        key={index}
                        state={"current"}
                        orderNumber={Order.id.split("-")[0]}
                        items={Order.orderedItems}
                        totalPrice={Order.totalAmount}
                        specialRequest={Order.specialRequest}
                        order={Order}
                        date={Order.createdAt}
                        orderStatus={Order.status}
                      />
                    );
                  })
                ) : (
                  <div className="ContainerSearch">
                    <img
                      alt="logo"
                      src={require("../../assets/myOrders.png")}
                      style={{
                        width: 100,
                        height: 130,
                        borderRadius: 10,
                        cursor: "pointer",
                        marginBottom: "1rem",
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: `etihadBook${i18n.language}`,
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      {t("No Orders Yet !")}{" "}
                    </Typography>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "rgba(0, 0, 0, 0.26)",
                        marginTop: "1rem",
                        fontFamily: `etihadBook${i18n.language}`,
                        fontWeight: "400",
                        wordWrap: "break-word",
                      }}
                    >
                      {t("Navigate lounge menu and start ordering")}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="AllOrders">
                {/* map here */}
                {pastOrders.length > 0 ? (
                  pastOrders.map((Order, index) => {
                    return (
                      <OrderCard
                        key={index}
                        state={"past"}
                        orderNumber={Order.id.split("-")[0]}
                        items={Order.orderedItems}
                        totalPrice={Order.totalAmount}
                        specialRequest={Order.specialRequest}
                        order={Order}
                        date={Order.createdAt}
                        orderStatus={Order.status}
                      />
                    );
                  })
                ) : (
                  <div className="ContainerSearch">
                    <img
                      alt="logo"
                      src={require("../../assets/myOrders.png")}
                      style={{
                        width: 100,
                        height: 130,
                        borderRadius: 10,
                        cursor: "pointer",
                        marginBottom: "1rem",
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: `etihadBook${i18n.language}`,
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      {t("No Past Orders Yet")}{" "}
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default MyOrder;
