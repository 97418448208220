/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEmailVerification = /* GraphQL */ `
  subscription OnCreateEmailVerification(
    $filter: ModelSubscriptionEmailVerificationFilterInput
  ) {
    onCreateEmailVerification(filter: $filter) {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEmailVerification = /* GraphQL */ `
  subscription OnUpdateEmailVerification(
    $filter: ModelSubscriptionEmailVerificationFilterInput
  ) {
    onUpdateEmailVerification(filter: $filter) {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEmailVerification = /* GraphQL */ `
  subscription OnDeleteEmailVerification(
    $filter: ModelSubscriptionEmailVerificationFilterInput
  ) {
    onDeleteEmailVerification(filter: $filter) {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
    onCreateReview(filter: $filter) {
      id
      accountID
      bookingID
      guestName
      guestId
      value
      reason
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
    onUpdateReview(filter: $filter) {
      id
      accountID
      bookingID
      guestName
      guestId
      value
      reason
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
    onDeleteReview(filter: $filter) {
      id
      accountID
      bookingID
      guestName
      guestId
      value
      reason
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
    onCreateAccount(filter: $filter) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      applicationLink
      applicationVersion
      enableApplication
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
    onUpdateAccount(filter: $filter) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      applicationLink
      applicationVersion
      enableApplication
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
    onDeleteAccount(filter: $filter) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      applicationLink
      applicationVersion
      enableApplication
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSocialLink = /* GraphQL */ `
  subscription OnCreateSocialLink(
    $filter: ModelSubscriptionSocialLinkFilterInput
  ) {
    onCreateSocialLink(filter: $filter) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSocialLink = /* GraphQL */ `
  subscription OnUpdateSocialLink(
    $filter: ModelSubscriptionSocialLinkFilterInput
  ) {
    onUpdateSocialLink(filter: $filter) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSocialLink = /* GraphQL */ `
  subscription OnDeleteSocialLink(
    $filter: ModelSubscriptionSocialLinkFilterInput
  ) {
    onDeleteSocialLink(filter: $filter) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateFeature = /* GraphQL */ `
  subscription OnCreateFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onCreateFeature(filter: $filter) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateFeature = /* GraphQL */ `
  subscription OnUpdateFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onUpdateFeature(filter: $filter) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteFeature = /* GraphQL */ `
  subscription OnDeleteFeature($filter: ModelSubscriptionFeatureFilterInput) {
    onDeleteFeature(filter: $filter) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLanguage = /* GraphQL */ `
  subscription OnCreateLanguage($filter: ModelSubscriptionLanguageFilterInput) {
    onCreateLanguage(filter: $filter) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLanguage = /* GraphQL */ `
  subscription OnUpdateLanguage($filter: ModelSubscriptionLanguageFilterInput) {
    onUpdateLanguage(filter: $filter) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLanguage = /* GraphQL */ `
  subscription OnDeleteLanguage($filter: ModelSubscriptionLanguageFilterInput) {
    onDeleteLanguage(filter: $filter) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMedia = /* GraphQL */ `
  subscription OnCreateMedia($filter: ModelSubscriptionMediaFilterInput) {
    onCreateMedia(filter: $filter) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMedia = /* GraphQL */ `
  subscription OnUpdateMedia($filter: ModelSubscriptionMediaFilterInput) {
    onUpdateMedia(filter: $filter) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMedia = /* GraphQL */ `
  subscription OnDeleteMedia($filter: ModelSubscriptionMediaFilterInput) {
    onDeleteMedia(filter: $filter) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onCreateAttachment(filter: $filter) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onUpdateAttachment(filter: $filter) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment(
    $filter: ModelSubscriptionAttachmentFilterInput
  ) {
    onDeleteAttachment(filter: $filter) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateConcept = /* GraphQL */ `
  subscription OnCreateConcept($filter: ModelSubscriptionConceptFilterInput) {
    onCreateConcept(filter: $filter) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      receiveWaiters
      WaiterNotificationOption
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateConcept = /* GraphQL */ `
  subscription OnUpdateConcept($filter: ModelSubscriptionConceptFilterInput) {
    onUpdateConcept(filter: $filter) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      receiveWaiters
      WaiterNotificationOption
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteConcept = /* GraphQL */ `
  subscription OnDeleteConcept($filter: ModelSubscriptionConceptFilterInput) {
    onDeleteConcept(filter: $filter) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      receiveWaiters
      WaiterNotificationOption
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateParentConcept = /* GraphQL */ `
  subscription OnCreateParentConcept(
    $filter: ModelSubscriptionParentConceptFilterInput
  ) {
    onCreateParentConcept(filter: $filter) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateParentConcept = /* GraphQL */ `
  subscription OnUpdateParentConcept(
    $filter: ModelSubscriptionParentConceptFilterInput
  ) {
    onUpdateParentConcept(filter: $filter) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteParentConcept = /* GraphQL */ `
  subscription OnDeleteParentConcept(
    $filter: ModelSubscriptionParentConceptFilterInput
  ) {
    onDeleteParentConcept(filter: $filter) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup($filter: ModelSubscriptionGroupFilterInput) {
    onCreateGroup(filter: $filter) {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($filter: ModelSubscriptionGroupFilterInput) {
    onUpdateGroup(filter: $filter) {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup($filter: ModelSubscriptionGroupFilterInput) {
    onDeleteGroup(filter: $filter) {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateGroupSetting = /* GraphQL */ `
  subscription OnCreateGroupSetting(
    $filter: ModelSubscriptionGroupSettingFilterInput
  ) {
    onCreateGroupSetting(filter: $filter) {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGroupSetting = /* GraphQL */ `
  subscription OnUpdateGroupSetting(
    $filter: ModelSubscriptionGroupSettingFilterInput
  ) {
    onUpdateGroupSetting(filter: $filter) {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGroupSetting = /* GraphQL */ `
  subscription OnDeleteGroupSetting(
    $filter: ModelSubscriptionGroupSettingFilterInput
  ) {
    onDeleteGroupSetting(filter: $filter) {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCustomerStats = /* GraphQL */ `
  subscription OnCreateCustomerStats(
    $filter: ModelSubscriptionCustomerStatsFilterInput
  ) {
    onCreateCustomerStats(filter: $filter) {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCustomerStats = /* GraphQL */ `
  subscription OnUpdateCustomerStats(
    $filter: ModelSubscriptionCustomerStatsFilterInput
  ) {
    onUpdateCustomerStats(filter: $filter) {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCustomerStats = /* GraphQL */ `
  subscription OnDeleteCustomerStats(
    $filter: ModelSubscriptionCustomerStatsFilterInput
  ) {
    onDeleteCustomerStats(filter: $filter) {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateFlag = /* GraphQL */ `
  subscription OnCreateFlag($filter: ModelSubscriptionFlagFilterInput) {
    onCreateFlag(filter: $filter) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateFlag = /* GraphQL */ `
  subscription OnUpdateFlag($filter: ModelSubscriptionFlagFilterInput) {
    onUpdateFlag(filter: $filter) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteFlag = /* GraphQL */ `
  subscription OnDeleteFlag($filter: ModelSubscriptionFlagFilterInput) {
    onDeleteFlag(filter: $filter) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateInterest = /* GraphQL */ `
  subscription OnCreateInterest($filter: ModelSubscriptionInterestFilterInput) {
    onCreateInterest(filter: $filter) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateInterest = /* GraphQL */ `
  subscription OnUpdateInterest($filter: ModelSubscriptionInterestFilterInput) {
    onUpdateInterest(filter: $filter) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteInterest = /* GraphQL */ `
  subscription OnDeleteInterest($filter: ModelSubscriptionInterestFilterInput) {
    onDeleteInterest(filter: $filter) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateReservationStatus = /* GraphQL */ `
  subscription OnCreateReservationStatus(
    $filter: ModelSubscriptionReservationStatusFilterInput
  ) {
    onCreateReservationStatus(filter: $filter) {
      id
      accountID
      name
      category
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
  }
`;
export const onUpdateReservationStatus = /* GraphQL */ `
  subscription OnUpdateReservationStatus(
    $filter: ModelSubscriptionReservationStatusFilterInput
  ) {
    onUpdateReservationStatus(filter: $filter) {
      id
      accountID
      name
      category
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
  }
`;
export const onDeleteReservationStatus = /* GraphQL */ `
  subscription OnDeleteReservationStatus(
    $filter: ModelSubscriptionReservationStatusFilterInput
  ) {
    onDeleteReservationStatus(filter: $filter) {
      id
      accountID
      name
      category
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
  }
`;
export const onCreateTimeSlot = /* GraphQL */ `
  subscription OnCreateTimeSlot($filter: ModelSubscriptionTimeSlotFilterInput) {
    onCreateTimeSlot(filter: $filter) {
      id
      conceptID
      name
      friendlyName
      bookings
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTimeSlot = /* GraphQL */ `
  subscription OnUpdateTimeSlot($filter: ModelSubscriptionTimeSlotFilterInput) {
    onUpdateTimeSlot(filter: $filter) {
      id
      conceptID
      name
      friendlyName
      bookings
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTimeSlot = /* GraphQL */ `
  subscription OnDeleteTimeSlot($filter: ModelSubscriptionTimeSlotFilterInput) {
    onDeleteTimeSlot(filter: $filter) {
      id
      conceptID
      name
      friendlyName
      bookings
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArea = /* GraphQL */ `
  subscription OnCreateArea($filter: ModelSubscriptionAreaFilterInput) {
    onCreateArea(filter: $filter) {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArea = /* GraphQL */ `
  subscription OnUpdateArea($filter: ModelSubscriptionAreaFilterInput) {
    onUpdateArea(filter: $filter) {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArea = /* GraphQL */ `
  subscription OnDeleteArea($filter: ModelSubscriptionAreaFilterInput) {
    onDeleteArea(filter: $filter) {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePlanItem = /* GraphQL */ `
  subscription OnCreatePlanItem($filter: ModelSubscriptionPlanItemFilterInput) {
    onCreatePlanItem(filter: $filter) {
      id
      areaID
      conceptID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePlanItem = /* GraphQL */ `
  subscription OnUpdatePlanItem($filter: ModelSubscriptionPlanItemFilterInput) {
    onUpdatePlanItem(filter: $filter) {
      id
      areaID
      conceptID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePlanItem = /* GraphQL */ `
  subscription OnDeletePlanItem($filter: ModelSubscriptionPlanItemFilterInput) {
    onDeletePlanItem(filter: $filter) {
      id
      areaID
      conceptID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTable = /* GraphQL */ `
  subscription OnCreateTable($filter: ModelSubscriptionTableFilterInput) {
    onCreateTable(filter: $filter) {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTable = /* GraphQL */ `
  subscription OnUpdateTable($filter: ModelSubscriptionTableFilterInput) {
    onUpdateTable(filter: $filter) {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTable = /* GraphQL */ `
  subscription OnDeleteTable($filter: ModelSubscriptionTableFilterInput) {
    onDeleteTable(filter: $filter) {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateObject = /* GraphQL */ `
  subscription OnCreateObject($filter: ModelSubscriptionObjectFilterInput) {
    onCreateObject(filter: $filter) {
      id
      conceptID
      label
      name
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
  }
`;
export const onUpdateObject = /* GraphQL */ `
  subscription OnUpdateObject($filter: ModelSubscriptionObjectFilterInput) {
    onUpdateObject(filter: $filter) {
      id
      conceptID
      label
      name
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
  }
`;
export const onDeleteObject = /* GraphQL */ `
  subscription OnDeleteObject($filter: ModelSubscriptionObjectFilterInput) {
    onDeleteObject(filter: $filter) {
      id
      conceptID
      label
      name
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
  }
`;
export const onCreateFriend = /* GraphQL */ `
  subscription OnCreateFriend($filter: ModelSubscriptionFriendFilterInput) {
    onCreateFriend(filter: $filter) {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateFriend = /* GraphQL */ `
  subscription OnUpdateFriend($filter: ModelSubscriptionFriendFilterInput) {
    onUpdateFriend(filter: $filter) {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteFriend = /* GraphQL */ `
  subscription OnDeleteFriend($filter: ModelSubscriptionFriendFilterInput) {
    onDeleteFriend(filter: $filter) {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking($filter: ModelSubscriptionBookingFilterInput) {
    onCreateBooking(filter: $filter) {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking($filter: ModelSubscriptionBookingFilterInput) {
    onUpdateBooking(filter: $filter) {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking($filter: ModelSubscriptionBookingFilterInput) {
    onDeleteBooking(filter: $filter) {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
    onUpdateComment(filter: $filter) {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
    onDeleteComment(filter: $filter) {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTimeline = /* GraphQL */ `
  subscription OnCreateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onCreateTimeline(filter: $filter) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTimeline = /* GraphQL */ `
  subscription OnUpdateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onUpdateTimeline(filter: $filter) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTimeline = /* GraphQL */ `
  subscription OnDeleteTimeline($filter: ModelSubscriptionTimelineFilterInput) {
    onDeleteTimeline(filter: $filter) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateServingArea = /* GraphQL */ `
  subscription OnCreateServingArea(
    $filter: ModelSubscriptionServingAreaFilterInput
  ) {
    onCreateServingArea(filter: $filter) {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateServingArea = /* GraphQL */ `
  subscription OnUpdateServingArea(
    $filter: ModelSubscriptionServingAreaFilterInput
  ) {
    onUpdateServingArea(filter: $filter) {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteServingArea = /* GraphQL */ `
  subscription OnDeleteServingArea(
    $filter: ModelSubscriptionServingAreaFilterInput
  ) {
    onDeleteServingArea(filter: $filter) {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCall = /* GraphQL */ `
  subscription OnCreateCall($filter: ModelSubscriptionCallFilterInput) {
    onCreateCall(filter: $filter) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const onUpdateCall = /* GraphQL */ `
  subscription OnUpdateCall($filter: ModelSubscriptionCallFilterInput) {
    onUpdateCall(filter: $filter) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const onDeleteCall = /* GraphQL */ `
  subscription OnDeleteCall($filter: ModelSubscriptionCallFilterInput) {
    onDeleteCall(filter: $filter) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminRole = /* GraphQL */ `
  subscription OnCreateAdminRole(
    $filter: ModelSubscriptionAdminRoleFilterInput
  ) {
    onCreateAdminRole(filter: $filter) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminRole = /* GraphQL */ `
  subscription OnUpdateAdminRole(
    $filter: ModelSubscriptionAdminRoleFilterInput
  ) {
    onUpdateAdminRole(filter: $filter) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminRole = /* GraphQL */ `
  subscription OnDeleteAdminRole(
    $filter: ModelSubscriptionAdminRoleFilterInput
  ) {
    onDeleteAdminRole(filter: $filter) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminGroup = /* GraphQL */ `
  subscription OnCreateAdminGroup(
    $filter: ModelSubscriptionAdminGroupFilterInput
  ) {
    onCreateAdminGroup(filter: $filter) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminGroup = /* GraphQL */ `
  subscription OnUpdateAdminGroup(
    $filter: ModelSubscriptionAdminGroupFilterInput
  ) {
    onUpdateAdminGroup(filter: $filter) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminGroup = /* GraphQL */ `
  subscription OnDeleteAdminGroup(
    $filter: ModelSubscriptionAdminGroupFilterInput
  ) {
    onDeleteAdminGroup(filter: $filter) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserConcepts = /* GraphQL */ `
  subscription OnCreateUserConcepts(
    $filter: ModelSubscriptionUserConceptsFilterInput
  ) {
    onCreateUserConcepts(filter: $filter) {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUserConcepts = /* GraphQL */ `
  subscription OnUpdateUserConcepts(
    $filter: ModelSubscriptionUserConceptsFilterInput
  ) {
    onUpdateUserConcepts(filter: $filter) {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUserConcepts = /* GraphQL */ `
  subscription OnDeleteUserConcepts(
    $filter: ModelSubscriptionUserConceptsFilterInput
  ) {
    onDeleteUserConcepts(filter: $filter) {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
  ) {
    onCreateTransaction(filter: $filter) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
  ) {
    onUpdateTransaction(filter: $filter) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
  ) {
    onDeleteTransaction(filter: $filter) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePaymentStat = /* GraphQL */ `
  subscription OnCreatePaymentStat(
    $filter: ModelSubscriptionPaymentStatFilterInput
  ) {
    onCreatePaymentStat(filter: $filter) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePaymentStat = /* GraphQL */ `
  subscription OnUpdatePaymentStat(
    $filter: ModelSubscriptionPaymentStatFilterInput
  ) {
    onUpdatePaymentStat(filter: $filter) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePaymentStat = /* GraphQL */ `
  subscription OnDeletePaymentStat(
    $filter: ModelSubscriptionPaymentStatFilterInput
  ) {
    onDeletePaymentStat(filter: $filter) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBookingGuest = /* GraphQL */ `
  subscription OnCreateBookingGuest(
    $filter: ModelSubscriptionBookingGuestFilterInput
  ) {
    onCreateBookingGuest(filter: $filter) {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBookingGuest = /* GraphQL */ `
  subscription OnUpdateBookingGuest(
    $filter: ModelSubscriptionBookingGuestFilterInput
  ) {
    onUpdateBookingGuest(filter: $filter) {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBookingGuest = /* GraphQL */ `
  subscription OnDeleteBookingGuest(
    $filter: ModelSubscriptionBookingGuestFilterInput
  ) {
    onDeleteBookingGuest(filter: $filter) {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onCreateCategory(filter: $filter) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      precedence
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onUpdateCategory(filter: $filter) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      precedence
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onDeleteCategory(filter: $filter) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      precedence
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMenuItem = /* GraphQL */ `
  subscription OnCreateMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
    onCreateMenuItem(filter: $filter) {
      id
      conceptID
      kioskID
      name
      description
      image
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      ratings
      prices
      choiceGroups
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMenuItem = /* GraphQL */ `
  subscription OnUpdateMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
    onUpdateMenuItem(filter: $filter) {
      id
      conceptID
      kioskID
      name
      description
      image
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      ratings
      prices
      choiceGroups
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMenuItem = /* GraphQL */ `
  subscription OnDeleteMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
    onDeleteMenuItem(filter: $filter) {
      id
      conceptID
      kioskID
      name
      description
      image
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      ratings
      prices
      choiceGroups
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePrice = /* GraphQL */ `
  subscription OnCreatePrice($filter: ModelSubscriptionPriceFilterInput) {
    onCreatePrice(filter: $filter) {
      id
      conceptID
      name
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePrice = /* GraphQL */ `
  subscription OnUpdatePrice($filter: ModelSubscriptionPriceFilterInput) {
    onUpdatePrice(filter: $filter) {
      id
      conceptID
      name
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePrice = /* GraphQL */ `
  subscription OnDeletePrice($filter: ModelSubscriptionPriceFilterInput) {
    onDeletePrice(filter: $filter) {
      id
      conceptID
      name
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateChoiceGroups = /* GraphQL */ `
  subscription OnCreateChoiceGroups(
    $filter: ModelSubscriptionChoiceGroupsFilterInput
  ) {
    onCreateChoiceGroups(filter: $filter) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateChoiceGroups = /* GraphQL */ `
  subscription OnUpdateChoiceGroups(
    $filter: ModelSubscriptionChoiceGroupsFilterInput
  ) {
    onUpdateChoiceGroups(filter: $filter) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteChoiceGroups = /* GraphQL */ `
  subscription OnDeleteChoiceGroups(
    $filter: ModelSubscriptionChoiceGroupsFilterInput
  ) {
    onDeleteChoiceGroups(filter: $filter) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateChoice = /* GraphQL */ `
  subscription OnCreateChoice($filter: ModelSubscriptionChoiceFilterInput) {
    onCreateChoice(filter: $filter) {
      id
      conceptID
      name
      price
      choiceGroupID
      choiceGroupName
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateChoice = /* GraphQL */ `
  subscription OnUpdateChoice($filter: ModelSubscriptionChoiceFilterInput) {
    onUpdateChoice(filter: $filter) {
      id
      conceptID
      name
      price
      choiceGroupID
      choiceGroupName
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteChoice = /* GraphQL */ `
  subscription OnDeleteChoice($filter: ModelSubscriptionChoiceFilterInput) {
    onDeleteChoice(filter: $filter) {
      id
      conceptID
      name
      price
      choiceGroupID
      choiceGroupName
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCart = /* GraphQL */ `
  subscription OnCreateCart($filter: ModelSubscriptionCartFilterInput) {
    onCreateCart(filter: $filter) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        __typename
      }
      specialRequest
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCart = /* GraphQL */ `
  subscription OnUpdateCart($filter: ModelSubscriptionCartFilterInput) {
    onUpdateCart(filter: $filter) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        __typename
      }
      specialRequest
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCart = /* GraphQL */ `
  subscription OnDeleteCart($filter: ModelSubscriptionCartFilterInput) {
    onDeleteCart(filter: $filter) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        __typename
      }
      specialRequest
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAdminStatus = /* GraphQL */ `
  subscription OnCreateAdminStatus(
    $filter: ModelSubscriptionAdminStatusFilterInput
  ) {
    onCreateAdminStatus(filter: $filter) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAdminStatus = /* GraphQL */ `
  subscription OnUpdateAdminStatus(
    $filter: ModelSubscriptionAdminStatusFilterInput
  ) {
    onUpdateAdminStatus(filter: $filter) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAdminStatus = /* GraphQL */ `
  subscription OnDeleteAdminStatus(
    $filter: ModelSubscriptionAdminStatusFilterInput
  ) {
    onDeleteAdminStatus(filter: $filter) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOnlineOrder = /* GraphQL */ `
  subscription OnCreateOnlineOrder(
    $filter: ModelSubscriptionOnlineOrderFilterInput
  ) {
    onCreateOnlineOrder(filter: $filter) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      userID
      userName
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      deliveryFee
      orderTime
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        __typename
      }
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      canceledAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOnlineOrder = /* GraphQL */ `
  subscription OnUpdateOnlineOrder(
    $filter: ModelSubscriptionOnlineOrderFilterInput
  ) {
    onUpdateOnlineOrder(filter: $filter) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      userID
      userName
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      deliveryFee
      orderTime
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        __typename
      }
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      canceledAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOnlineOrder = /* GraphQL */ `
  subscription OnDeleteOnlineOrder(
    $filter: ModelSubscriptionOnlineOrderFilterInput
  ) {
    onDeleteOnlineOrder(filter: $filter) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      userID
      userName
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      deliveryFee
      orderTime
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        __typename
      }
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      canceledAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOrderStatus = /* GraphQL */ `
  subscription OnCreateOrderStatus(
    $filter: ModelSubscriptionOrderStatusFilterInput
  ) {
    onCreateOrderStatus(filter: $filter) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOrderStatus = /* GraphQL */ `
  subscription OnUpdateOrderStatus(
    $filter: ModelSubscriptionOrderStatusFilterInput
  ) {
    onUpdateOrderStatus(filter: $filter) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOrderStatus = /* GraphQL */ `
  subscription OnDeleteOrderStatus(
    $filter: ModelSubscriptionOrderStatusFilterInput
  ) {
    onDeleteOrderStatus(filter: $filter) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDiscountCoupon = /* GraphQL */ `
  subscription OnCreateDiscountCoupon(
    $filter: ModelSubscriptionDiscountCouponFilterInput
  ) {
    onCreateDiscountCoupon(filter: $filter) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDiscountCoupon = /* GraphQL */ `
  subscription OnUpdateDiscountCoupon(
    $filter: ModelSubscriptionDiscountCouponFilterInput
  ) {
    onUpdateDiscountCoupon(filter: $filter) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDiscountCoupon = /* GraphQL */ `
  subscription OnDeleteDiscountCoupon(
    $filter: ModelSubscriptionDiscountCouponFilterInput
  ) {
    onDeleteDiscountCoupon(filter: $filter) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMobileNotification = /* GraphQL */ `
  subscription OnCreateMobileNotification(
    $filter: ModelSubscriptionMobileNotificationFilterInput
  ) {
    onCreateMobileNotification(filter: $filter) {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMobileNotification = /* GraphQL */ `
  subscription OnUpdateMobileNotification(
    $filter: ModelSubscriptionMobileNotificationFilterInput
  ) {
    onUpdateMobileNotification(filter: $filter) {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMobileNotification = /* GraphQL */ `
  subscription OnDeleteMobileNotification(
    $filter: ModelSubscriptionMobileNotificationFilterInput
  ) {
    onDeleteMobileNotification(filter: $filter) {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserPushToken = /* GraphQL */ `
  subscription OnCreateUserPushToken(
    $filter: ModelSubscriptionUserPushTokenFilterInput
  ) {
    onCreateUserPushToken(filter: $filter) {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUserPushToken = /* GraphQL */ `
  subscription OnUpdateUserPushToken(
    $filter: ModelSubscriptionUserPushTokenFilterInput
  ) {
    onUpdateUserPushToken(filter: $filter) {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUserPushToken = /* GraphQL */ `
  subscription OnDeleteUserPushToken(
    $filter: ModelSubscriptionUserPushTokenFilterInput
  ) {
    onDeleteUserPushToken(filter: $filter) {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateNotificationReceiver = /* GraphQL */ `
  subscription OnCreateNotificationReceiver(
    $filter: ModelSubscriptionNotificationReceiverFilterInput
  ) {
    onCreateNotificationReceiver(filter: $filter) {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateNotificationReceiver = /* GraphQL */ `
  subscription OnUpdateNotificationReceiver(
    $filter: ModelSubscriptionNotificationReceiverFilterInput
  ) {
    onUpdateNotificationReceiver(filter: $filter) {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteNotificationReceiver = /* GraphQL */ `
  subscription OnDeleteNotificationReceiver(
    $filter: ModelSubscriptionNotificationReceiverFilterInput
  ) {
    onDeleteNotificationReceiver(filter: $filter) {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateZone = /* GraphQL */ `
  subscription OnCreateZone($filter: ModelSubscriptionZoneFilterInput) {
    onCreateZone(filter: $filter) {
      id
      conceptID
      location
      deliveryFee
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateZone = /* GraphQL */ `
  subscription OnUpdateZone($filter: ModelSubscriptionZoneFilterInput) {
    onUpdateZone(filter: $filter) {
      id
      conceptID
      location
      deliveryFee
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteZone = /* GraphQL */ `
  subscription OnDeleteZone($filter: ModelSubscriptionZoneFilterInput) {
    onDeleteZone(filter: $filter) {
      id
      conceptID
      location
      deliveryFee
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserIP = /* GraphQL */ `
  subscription OnCreateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
    onCreateUserIP(filter: $filter) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUserIP = /* GraphQL */ `
  subscription OnUpdateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
    onUpdateUserIP(filter: $filter) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUserIP = /* GraphQL */ `
  subscription OnDeleteUserIP($filter: ModelSubscriptionUserIPFilterInput) {
    onDeleteUserIP(filter: $filter) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMainCategory = /* GraphQL */ `
  subscription OnCreateMainCategory(
    $filter: ModelSubscriptionMainCategoryFilterInput
  ) {
    onCreateMainCategory(filter: $filter) {
      id
      conceptID
      name
      subName
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMainCategory = /* GraphQL */ `
  subscription OnUpdateMainCategory(
    $filter: ModelSubscriptionMainCategoryFilterInput
  ) {
    onUpdateMainCategory(filter: $filter) {
      id
      conceptID
      name
      subName
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMainCategory = /* GraphQL */ `
  subscription OnDeleteMainCategory(
    $filter: ModelSubscriptionMainCategoryFilterInput
  ) {
    onDeleteMainCategory(filter: $filter) {
      id
      conceptID
      name
      subName
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSubCategory = /* GraphQL */ `
  subscription OnCreateSubCategory(
    $filter: ModelSubscriptionSubCategoryFilterInput
  ) {
    onCreateSubCategory(filter: $filter) {
      id
      conceptID
      name
      subName
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSubCategory = /* GraphQL */ `
  subscription OnUpdateSubCategory(
    $filter: ModelSubscriptionSubCategoryFilterInput
  ) {
    onUpdateSubCategory(filter: $filter) {
      id
      conceptID
      name
      subName
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSubCategory = /* GraphQL */ `
  subscription OnDeleteSubCategory(
    $filter: ModelSubscriptionSubCategoryFilterInput
  ) {
    onDeleteSubCategory(filter: $filter) {
      id
      conceptID
      name
      subName
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateShift = /* GraphQL */ `
  subscription OnCreateShift($filter: ModelSubscriptionShiftFilterInput) {
    onCreateShift(filter: $filter) {
      id
      accountID
      name
      precedence
      startTime
      endTime
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateShift = /* GraphQL */ `
  subscription OnUpdateShift($filter: ModelSubscriptionShiftFilterInput) {
    onUpdateShift(filter: $filter) {
      id
      accountID
      name
      precedence
      startTime
      endTime
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteShift = /* GraphQL */ `
  subscription OnDeleteShift($filter: ModelSubscriptionShiftFilterInput) {
    onDeleteShift(filter: $filter) {
      id
      accountID
      name
      precedence
      startTime
      endTime
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePreparationArea = /* GraphQL */ `
  subscription OnCreatePreparationArea(
    $filter: ModelSubscriptionPreparationAreaFilterInput
  ) {
    onCreatePreparationArea(filter: $filter) {
      id
      accountID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      printerIP
      printerPort
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePreparationArea = /* GraphQL */ `
  subscription OnUpdatePreparationArea(
    $filter: ModelSubscriptionPreparationAreaFilterInput
  ) {
    onUpdatePreparationArea(filter: $filter) {
      id
      accountID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      printerIP
      printerPort
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePreparationArea = /* GraphQL */ `
  subscription OnDeletePreparationArea(
    $filter: ModelSubscriptionPreparationAreaFilterInput
  ) {
    onDeletePreparationArea(filter: $filter) {
      id
      accountID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      printerIP
      printerPort
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateQRcode = /* GraphQL */ `
  subscription OnCreateQRcode($filter: ModelSubscriptionQRcodeFilterInput) {
    onCreateQRcode(filter: $filter) {
      id
      tableID
      conceptID
      tableName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateQRcode = /* GraphQL */ `
  subscription OnUpdateQRcode($filter: ModelSubscriptionQRcodeFilterInput) {
    onUpdateQRcode(filter: $filter) {
      id
      tableID
      conceptID
      tableName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteQRcode = /* GraphQL */ `
  subscription OnDeleteQRcode($filter: ModelSubscriptionQRcodeFilterInput) {
    onDeleteQRcode(filter: $filter) {
      id
      tableID
      conceptID
      tableName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCallWaiter = /* GraphQL */ `
  subscription OnCreateCallWaiter(
    $filter: ModelSubscriptionCallWaiterFilterInput
  ) {
    onCreateCallWaiter(filter: $filter) {
      id
      conceptID
      tableID
      tableName
      waiterID
      waiterName
      userID
      count
      status
      deleted
      createdAt
      acceptedAt
      arrivedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCallWaiter = /* GraphQL */ `
  subscription OnUpdateCallWaiter(
    $filter: ModelSubscriptionCallWaiterFilterInput
  ) {
    onUpdateCallWaiter(filter: $filter) {
      id
      conceptID
      tableID
      tableName
      waiterID
      waiterName
      userID
      count
      status
      deleted
      createdAt
      acceptedAt
      arrivedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCallWaiter = /* GraphQL */ `
  subscription OnDeleteCallWaiter(
    $filter: ModelSubscriptionCallWaiterFilterInput
  ) {
    onDeleteCallWaiter(filter: $filter) {
      id
      conceptID
      tableID
      tableName
      waiterID
      waiterName
      userID
      count
      status
      deleted
      createdAt
      acceptedAt
      arrivedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
