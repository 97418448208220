import { EndPoints } from "../Constants/Enums";
import Cookies from 'js-cookie';

async function createCart(conceptID, orderedItems, totalPrice ,createdAt) {
  try {
    const operationId = 12;
    const userID = JSON.parse( Cookies.get('userCookie')).uid
    const requestBody = {
      operationId,
      conceptID,
      userID,
      orderedItems,
      totalPrice,
      createdAt,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(EndPoints.AppOperationsLambda, options);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.error("Error creating Cart :", error);
    throw error;
  }
}

export default createCart;
