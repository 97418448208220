import axios from "axios";
import { EndPoints } from "../Constants/Enums";

async function sendPushNotification(data, title2, message) {
  try {
    const reqBody = {
      userIds: data,
      notification: {
        title: title2,
        body: message,
        page: "",
      },
    };

    const response = await axios.post(EndPoints.pushNotification, reqBody);
    return response.data;
  } catch (error) {
    console.error("Error storing token:", error.message);
  }
}

export default sendPushNotification;
