// actions
const SET = "categories/SET";
const DEFAULT_STATE = {
  listing: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });

    default:
      return state;
  }
};

export default reducer;

// action creators
export const setCategories = (listing) => ({ listing, type: SET });
