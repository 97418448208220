import { useEffect, useState } from "react";

import "./Home.css";
import NavBar from "../../Components/NavBar/NavBar";
import getCategoryData from "../../Services/getCategoryData";
import getCart from "../../Services/getCart";
import MainLoader from "../../Components/MainLoader/MainLoader";
import SubCategoriesView from "../../Components/SubCategoriesView/SubCategoriesView";
import { Typography, Grid, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  capitalizeSentence,
  numberTranslation,
  getTimeInUAE,
} from "../../helpers/utils";
import NavExtension from "../../Components/NavExtension/NavExtension";
import { useDispatch, useSelector } from "react-redux";
import getMainCategories from "../../Services/getMainCategories";
import getSubCategories from "../../Services/getSubCategories";
import { setEncryptedID, setTable, setTableId } from "../../store/ducks/table";
import { setCategories } from "../../store/ducks/categories";
import {
  setMainCategories,
  setSelectedMainCategoryTab,
} from "../../store/ducks/mainCategories";
import { setSubCategories, setPDF } from "../../store/ducks/subCategories";
import { setCart, setQuantity } from "../../store/ducks/cart";
import { cacheData, getCachedData } from "../../Services/getCachedData";
import {
  bucketURL,
  Keys,
  MySecretKey,
  OrderStatus,
} from "../../Constants/Enums";
import { validateTable, decryptID } from "../../Services/validateTable";
import Guard from "../Guard/Guard";
import getConcept from "../../Services/getConcept";
import { setConcept } from "../../store/ducks/concept";
import { setMainView } from "../../store/ducks/configs";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import HomeTrackOrder from "../../Components/HomeTrackOrder/HomeTrackOrder";
import useApp from "../../hooks/useApp";
import {
  setSelectedSubCategory,
  setSelectedCategory,
  setShowSpaOptions,
} from "../../store/ducks/app";
import { setSubOrder } from "../../store/ducks/configs";
import LoyaltyView from "../../Components/LoyaltyView/LoyaltyView";

function Home({ isMainSelected, handleIsMainSelected, handleSelectedMain }) {
  const order = Cookies.get("trackedOrder")
    ? JSON.parse(Cookies.get("trackedOrder"))
    : null;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredMain, setFilteredMain] = useState([]);
  const [viewMainCategories, setViewMainCategories] = useState(false);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [list, setList] = useState([]);

  let concept = useSelector((state) => state.concept.listing);
  const selectedMainCategoryTab = useSelector(
    (state) => state.mainCategories.selectedMainCategory
  );
  const mainCategories = useSelector((state) => state.mainCategories.listing);
  const quantity = useSelector((state) => state.cart.quantity);
  const cart = useSelector((state) => state.cart.listing);
  const tableId = useSelector((state) => state.table.tableId);
  const encryptedID = useSelector((state) => state.table.encryptedID);
  const table = useSelector((state) => state.table.listing);

  const [viewLoyaltyOptions, setLoyaltyOptions] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { showError } = useApp();

  const locations = [];
  const cars = [];
  const branches = [];
  const items = { locations: locations, cars: cars, branches: branches };

  const dispatchCategories = (category) => {
    dispatch(setCategories(category));
  };

  const dispatchSubCategories = (category) => {
    dispatch(setSubCategories(category));
  };

  const dispatchCart = (cart) => {
    dispatch(setCart(cart));
  };

  function removeDuplicates(array, key) {
    const seen = new Set();
    return array.filter((item) => {
      const itemKey = item[key];
      if (!seen.has(itemKey)) {
        seen.add(itemKey);
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    const fetch = async () => {
      if (!table || !tableId) {
        const encryptedTableID = await getURL();
        await validateTableID(encryptedTableID);
      } else {
        await fetchCategories(table);
        await fetchCart(table);
        setIsLoading(false);
      }
    };

    fetch();
  }, [encryptedID]);

  useEffect(() => {
    if (i18n.language === "Arabic" || i18n.language === "he") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [i18n.language]);

  useEffect(() => {
    const encryptedTableID = params.id;
    dispatch(setEncryptedID(encryptedTableID));
  }, [params]);

  const getURL = async () => {
    const encryptedTableID = params.id;
    dispatch(setEncryptedID(encryptedTableID));
    return encryptedTableID;
  };

  const validateTableID = async (encryptedID) => {
    setIsLoading(true);
    try {
      if (encryptedID === "" || encryptedID === undefined) {
        setIsLoading(false);
        return;
      }

      const decryptedID = decryptID(encryptedID, MySecretKey);
      const QRcode = await validateTable(decryptedID);

      dispatch(setMainView(false));
      dispatch(setTableId(decryptedID));

      if (QRcode) {
        if (QRcode.deleted !== "0") {
          // Show invalid QR code message
          showError(" This QR Code is invalid. Please scan a valid one.");
          setIsLoading(false);
          return;
        }

        dispatch(setTable(QRcode));
        localStorage.setItem("encryptedID", encryptedID);

        //Create Cookie for a new user
        if (!Cookies.get("userCookie")) {
          const cookieData = {
            uid: uuidv4(), // Example UID
            hasCart: false, // Example username
            canReview: true,
          };
          Cookies.set("userCookie", cookieData, { expires: 1 });
        }

        await fetchCategories(QRcode);
        await fetchCart(QRcode);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const fetchCategories = async (table) => {
    // Get cached data
    let cachedMain = await getCachedData(Keys.MAIN_CATEGORIES);
    let cachedSub = await getCachedData(Keys.SUB_CATEGORIES);

    if (!concept) {
      concept = await getConcept(table.conceptID);
      dispatch(setConcept(concept));
    }

    if (cachedMain && cachedSub) {
      setFilteredMain(mainCategories);
      dispatch(setMainCategories(cachedMain));
      dispatchSubCategories(cachedSub);

      // List Main Categories
      if (cachedMain.length > 0)
        setList(
          cachedMain
            .filter((item) => {
              return item.guestView && isCategoryAvailable(item);
            })
            .sort((a, b) => {
              return a.precedence - b.precedence;
            })
        );
      else {
        setList(
          cachedSub
            .filter((item) => {
              return item.guestView && isCategoryAvailable(item);
            })
            .sort((a, b) => {
              return a.precedence - b.precedence;
            })
        );
      }

      // if (concept.name.includes("FC")) {
      //   // let list=removeDuplicates(cachedSub, "name")
      //   setList(
      //     cachedSub
      //       .filter((item) => {
      //         return item.guestView && isCategoryAvailable(item);
      //       })
      //       .sort((a, b) => {
      //         return a.precedence - b.precedence;
      //       })
      //   );
      // } else {
      //   // let list=removeDuplicates(cachedMain, "name")
      //   setList(
      //     cachedMain
      //       .filter((item) => {
      //         return item.guestView && isCategoryAvailable(item);
      //       })
      //       .sort((a, b) => {
      //         return a.precedence - b.precedence;
      //       })
      //   );
      // }
    } else {
      // Get main category
      const data = await Promise.all([getMainCategories(), getSubCategories()]);

      let mainCategoriesList = data[0];
      let subCategoriesList = data[1];
      let mainCategoriesListIDs = [];

      mainCategoriesList = mainCategoriesList.filter((mainCategory) => {
        return concept.id === mainCategory.conceptID;
      });
      mainCategoriesList.map((mainCategory) => {
        return mainCategoriesListIDs.push(mainCategory.id);
      });

      // List Main Categories
      if (mainCategoriesList && mainCategoriesList.length > 0) {
        subCategoriesList = subCategoriesList.filter((subCategory) => {
          return mainCategoriesListIDs.includes(subCategory.mainCategoryID);
        });
        setList(
          mainCategoriesList
            .filter((item) => {
              return item.guestView && isCategoryAvailable(item);
            })
            .sort((a, b) => {
              return a.precedence - b.precedence;
            })
        );
      } else {
        subCategoriesList = subCategoriesList.filter((subCategory) => {
          return concept.id === subCategory.conceptID;
        });
        setList(
          subCategoriesList
            .filter((item) => {
              return item.guestView && isCategoryAvailable(item);
            })
            .sort((a, b) => {
              return a.precedence - b.precedence;
            })
        );
      }

      // if (concept.name.includes("FC")) {
      //   let mainCategoriesListIDs = [];

      //   mainCategoriesList = mainCategoriesList.filter((mainCategory) => {
      //     return concept.id === mainCategory.conceptID;
      //   });
      //   mainCategoriesList.map((mainCategory) => {
      //     return mainCategoriesListIDs.push(mainCategory.id);
      //   });
      //   subCategoriesList = subCategoriesList.filter((subCategory) => {
      //     return mainCategoriesListIDs.includes(subCategory.mainCategoryID);
      //   });

      //   setList(
      //     subCategoriesList
      //       .filter((item) => {
      //         return item.guestView && isCategoryAvailable(item);
      //       })
      //       .sort((a, b) => {
      //         return a.precedence - b.precedence;
      //       })
      //   );
      // } else {
      //   let mainCategoriesListIDs = [];

      //   mainCategoriesList = mainCategoriesList.filter((mainCategory) => {
      //     return concept.id === mainCategory.conceptID;
      //   });
      //   mainCategoriesList.map((mainCategory) => {
      //     return mainCategoriesListIDs.push(mainCategory.id);
      //   });
      //   subCategoriesList = subCategoriesList.filter((subCategory) => {
      //     return mainCategoriesListIDs.includes(subCategory.mainCategoryID);
      //   });

      //   setList(
      //     mainCategoriesList
      //       .filter((item) => {
      //         return item.guestView && isCategoryAvailable(item);
      //       })
      //       .sort((a, b) => {
      //         return a.precedence - b.precedence;
      //       })
      //   );
      // }

      setFilteredMain(
        mainCategoriesList.sort((a, b) => {
          return a.precedence - b.precedence;
        })
      );
      dispatch(
        setMainCategories(
          mainCategoriesList.sort((a, b) => {
            return a.precedence - b.precedence;
          })
        )
      );
      dispatchSubCategories(
        subCategoriesList.sort((a, b) => {
          return a.precedence - b.precedence;
        })
      );

      // Save them for caching
      await cacheData(
        Keys.MAIN_CATEGORIES,
        mainCategoriesList.sort((a, b) => {
          return a.precedence - b.precedence;
        })
      );
      await cacheData(
        Keys.SUB_CATEGORIES,
        subCategoriesList.sort((a, b) => {
          return a.precedence - b.precedence;
        })
      );
    }
  };
  const fetchCart = async (table) => {
    const userCookie = JSON.parse(Cookies.get("userCookie"));

    if (userCookie.hasCart && Cookies.get("cart")) {
      const cachedCart = JSON.parse(Cookies.get("cart"));

      if (cachedCart) {
        dispatchCart(cachedCart);
        if (cachedCart?.orderedItems?.length > 0) {
          const totalQuantity = cachedCart.orderedItems.reduce(
            (total, orderedItem) => total + orderedItem.quantity,
            0
          );
          dispatch(setQuantity(totalQuantity));
        } else {
          dispatch(setQuantity(0));
        }
      } else {
        const cartDataResponse = await getCart();
        const cartData = cartDataResponse[0];
        dispatchCart(cartData);
        if (cartData?.orderedItems?.length > 0) {
          const totalQuantity = cartData.orderedItems.reduce(
            (total, orderedItem) => total + orderedItem.quantity,
            0
          );
          dispatch(setQuantity(totalQuantity));
        } else {
          dispatch(setQuantity(0));
        }

        dispatch(setCart(cartData));
        Cookies.set("cart", JSON.stringify(cartData));

        await cacheData(Keys.CART, cartData);
      }
    }
  };

  const handleViewBasket = () => {
    navigate("/cart", { state: { CartData: cart } });
  };

  function isCategoryAvailable(category) {
    const now = getTimeInUAE();
    let startTime, endTime;
    if (category.closeTime === "00:00") {
      // If closeTime is "00:00", the category is open all day
      startTime = new Date(now.toDateString() + " 00:00");
      endTime = new Date(now.toDateString() + " 23:59"); // Set endTime to the end of the day
    } else {
      startTime = new Date(now.toDateString() + " " + category.openTime);
      endTime = new Date(now.toDateString() + " " + category.closeTime);
    }
    const isOpenAllDay = category.closeTime === "00:00";
    const isOpenNow = (now >= startTime && now <= endTime) || isOpenAllDay;
    return isOpenNow || isOpenAllDay;
  }

  const handleSubCategoryClick = async (subCategory) => {
    if (concept.viewOnly) {
      handlePDF(subCategory);
    } else {
      setIsLoading(true);
      dispatch(setSelectedSubCategory(subCategory));
      dispatch(setSubOrder(subCategory.guestOrder));
      // Navigate to Menu
      let categoriesList = await getCategoryData(subCategory.id);
      // console.log(categoriesList);
      dispatch(setSelectedCategory(categoriesList[0]));
      setIsLoading(false);
      navigate("/menuList", {
        state: { Categories: categoriesList },
      });
    }
  };

  const openLoyaltyPDFLink = () => {
    // Banner PDF Action
    const link = "PDFs/LOYALTY+PROGRAMME+0224.pdf";
    dispatch(setPDF(link));
    navigate("/pdfview", { state: { pdf: link } });
  };

  const navigateToLoyaltyWebsite = () => {
    const loyaltyLink = "https://www.etihadguest.com/en/quick-enrolment.html";
    window.location.href = loyaltyLink;
  };

  const openPdfLink = () => {
    dispatch(setSelectedMainCategoryTab(null));
    dispatch(setShowSpaOptions(false));

    const link = "PDFs/Lounge+Map+v1.1+0724%5B47%5D_rotated.pdf";

    dispatch(setPDF(link));
    navigate("/pdfview", { state: { pdf: link } });
  };

  const handlePDF = (subCategory) => {
    // View PDF
    dispatch(setPDF(subCategory.menuPDF));
    navigate("/pdfview", { state: { pdf: subCategory.menuPDF } });
  };

  const handleOnClick = (item) => {
    // Save selected category
    dispatch(setSelectedMainCategoryTab(item));

    // View PDF in case this main category doesn't have any sub category
    if (item.__typename === "SubCategory") {
      if (concept.viewOnly && item.menuPDF) {
        handlePDF(item);
      } else {
        handleSubCategoryClick(item);
      }
    } else {
      if (item.menuPDF) {
        handlePDF(item);
      } else {
        setSelectedMainCategory(item);
        setViewMainCategories(true);
      }
    }

    // if (concept.name.includes("FC")) {
    //   // item === subCategory
    //   if (concept.viewOnly) {
    //     handlePDF(item);
    //   } else {
    //     handleSubCategoryClick(item);
    //   }
    // } // item === mainCategory
    // else {
    //   setSelectedMainCategory(item);
    //   setViewMainCategories(true);
    // }
  };
  useEffect(() => {
    // Re-open past selected category
    if (selectedMainCategoryTab) {
      setSelectedMainCategory(selectedMainCategoryTab);
      setViewMainCategories(true);
    }

    let interval = null;
    interval = setInterval(() => {
      let newList = list.filter((item) => {
        return item.guestView && isCategoryAvailable(item);
      });
      if (!(JSON.stringify(newList) === JSON.stringify(list))) {
        setList(
          newList.sort((a, b) => {
            return b.precedence - a.precedence;
          })
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [list]);

  if (!table) {
    return (
      <>
        {isLoading ? (
          <div className="Container centerLoading">
            <MainLoader />
          </div>
        ) : (
          <Guard />
        )}
      </>
    );
  }

  return (
    <div className="Container">
      {isLoading ? (
        <div className="Container centerLoading">
          <MainLoader />
        </div>
      ) : (
        <>
          <div className="NavBarContainer">
            <NavBar items={items} />
          </div>
          <div
            style={{ width: "100%", height: "100%", marginTop: " 4.5rem" }}
            onClick={openPdfLink}
          >
            <div className="image-container">
              <img
                className="background-image"
                alt="background"
                // src={require("../../assets/explor.png")}
                src={require("../../assets/explorV2.png")}
              />
              <div className="overlay-text">
                <div style={{ fontFamily: `${i18n.language}"etihadBook"` }}>
                  {t("Explore")}
                </div>
              </div>
            </div>
          </div>

          <div className="SearchContainer" style={{ width: "100%" }}>
            {order &&
              order.status !== OrderStatus.delivered &&
              order.status !== OrderStatus.cancelled && (
                <HomeTrackOrder order={order} />
              )}

            {/* <CallWaiterCard /> */}
          </div>

          {list.length === 0 && (
            <div className="ContainerSearch">
              {t("Main category is not available")}
            </div>
          )}

          <Grid container spacing={"10px"} className="AllSectionsImage">
            {list &&
              list.length > 0 &&
              list.map((item, index) => {
                return (
                  <Grid container item xs={12} key={item.id}>
                    <Grid
                      item
                      xs={12}
                      onClick={() => {
                        handleOnClick(item);
                      }}
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        justifyItems={"center"}
                        alignContent={"center"}
                        alignItems={"center"}
                        item
                        xs={12}
                        style={{
                          width: "100%",
                          height: 80,
                          background:
                            "linear-gradient(135deg, white 0%, #ECE7DF 52%, #EAE6DD 56%, #FCF3DE 100%)",
                          borderRadius: 10,
                          border: "2px #D2B56F solid",
                        }}
                      >
                        <Grid
                          container
                          direction={"row"}
                          item
                          xs={12}
                          justifyContent={"center"}
                          justifyItems={"center"}
                          alignContent={"center"}
                          alignItems={"center"}
                        >
                          {item.image && (
                            <>
                              <Box
                                component="img"
                                src={`${bucketURL}${item.image}`}
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  transition: "opacity 400ms ease 0ms",
                                  paddingLeft: ".5rem",
                                  paddingRight: ".5rem",
                                }}
                              />
                            </>
                          )}
                          <Typography
                            style={{
                              color: "black",
                              fontSize: 25,
                              fontFamily: `etihadBook${i18n.language}`,
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            {new Map(JSON.parse(item.multiLanguages))?.get(
                              `${i18n.language}-${"name"}`
                            ) ?? item.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              color: "black",
                              fontSize: 15,
                              fontFamily: `etihadBook${i18n.language}`,
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            {capitalizeSentence(
                              new Map(JSON.parse(item.multiLanguages))?.get(
                                `${i18n.language}-${"subName"}`
                              ) ??
                                item.subName ??
                                ""
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            {viewMainCategories && (
              <SubCategoriesView
                selectedMainCategory={selectedMainCategory}
                onSelect={handleSubCategoryClick}
                toggleView={() => {
                  setViewMainCategories(false);
                }}
              ></SubCategoriesView>
            )}
          </Grid>

          <NavExtension
            items={items}
            order={
              Cookies.get("trackedOrder") ? Cookies.get("trackedOrder") : null
            }
          />
          <div
            className="article"
            onClick={() => {
              // Display two options (Menu, Etihad Guest)
              //setLoyaltyOptions(true);

              navigateToLoyaltyWebsite();
            }}
          >
            <div className="image-container">
              <img
                className="background-image"
                alt="background"
                src={
                  i18n.language === "Arabic"
                    ? require("../../assets/bannerArabic.png")
                    : require("../../assets/banner.png")
                }
              />
            </div>
          </div>

          {/* Cart Footer Card */}
          {cart && cart?.orderedItems?.length >= 0 && quantity !== 0 && (
            <div className="CartDisplay" onClick={handleViewBasket}>
              <div className="CartDisplayInfo">
                <Typography
                  fontFamily={`etihadBook${i18n.language}`}
                  fontWeight={700}
                >
                  <span
                    style={{
                      display: "inline-block",
                      height: "20px",
                      lineHeight: "20px",
                      textAlign: "center",
                      borderRadius: "30%",
                      border: "2px solid #fff",
                      padding: "3px",
                    }}
                  >
                    {numberTranslation(quantity, i18n.language)}
                  </span>{" "}
                </Typography>
                <Typography
                  fontSize={20}
                  fontFamily={`etihadBook${i18n.language}`}
                  fontWeight={700}
                  width={"100%"}
                  textAlign={"center"}
                  justifyContent={"center"}
                >
                  {t("basket display")}
                </Typography>
                {cart.totalPrice !== 0 && (
                  <Typography>
                    {t("total")} {cart.totalPrice}
                  </Typography>
                )}
              </div>
            </div>
          )}

          {/* Loyalty Menu */}
          {/* {viewLoyaltyOptions && (
            <LoyaltyView
              onSelect={openLoyaltyPDFLink}
              toggleView={() => {
                setLoyaltyOptions(false);
              }}
            ></LoyaltyView>
          )} */}
        </>
      )}
    </div>
  );
}

export default Home;
