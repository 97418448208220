import AsyncStorage from "@react-native-async-storage/async-storage";

export const getCachedData = async (key) => {
  // Retrieve the cached data from AsyncStorage
  const cachedData = await AsyncStorage.getItem(key);
  return cachedData ? JSON.parse(cachedData) : null;
};

export const cacheData = async (key, data) => {
  await AsyncStorage.setItem(key, JSON.stringify(data));
};

export const removeCachedData = async () => {
  await AsyncStorage.clear();
};
