import React from "react";
import "./TrackOrder.css";
import RadioGroupRating from "../RatingEmojies/RatingEmojies";

function TrackOrder({ onClose, orderNumber, order }) {
  return (
    <div className="form">
      <div className="info">
        <span className="title2">Tracking your order</span>
        <p className="description">Order number: #{orderNumber}</p>
      </div>

      <div className="input-fields">
        {order.status === "pending" ? (
          <div className="loader2"></div>
        ) : (
          order.status === "accepted" && (
            <div className="loader-wrapper">
              <div className="packman"></div>
              <div className="dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          )
        )}
      </div>
      {order.status === "pending" ? (
        <p>Your order is currently pending and will be prepared very soon.</p>
      ) : (
        order.status === "accepted" && (
          <p>Your order is accepted and being prepared.</p>
        )
      )}
      <div className="action-btns">
        <a className="verify" onClick={onClose}>
          Done
        </a>
        {order.status === "pending" && (
          <a className="verify" onClick={() => alert("Coming soon")}>
            Edit
          </a>
        )}
      </div>
    </div>
  );
}

export default TrackOrder;
