import { useEffect, useState } from "react";
import "./SubCategories.css";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/NavBar/NavBar";
import MainLoader from "../../Components/MainLoader/MainLoader";
import { useDispatch, useSelector } from "react-redux";
import { OrderStatus } from "../../Constants/Enums";
import HomeTrackOrder from "../../Components/HomeTrackOrder/HomeTrackOrder";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import MainCategory from "../../Components/MainCategory/MainCategory";
import { setSubOrder } from "../../store/ducks/configs";
import {
  setSelectedCategory,
  setSelectedSubCategory,
} from "../../store/ducks/app";

function SubCategories() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const subCategories = location.state.filteredSubs;

  const [empty, setEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  const order = Cookies.get("trackedOrder")
    ? JSON.parse(Cookies.get("trackedOrder"))
    : null;

  const quantity = useSelector((state) => state.cart.quantity);
  const cart = useSelector((state) => state.cart.listing);
  const categories = useSelector((state) => state.categories.listing);

  useEffect(() => {
    if (subCategories.length === 0) {
      setEmpty(true);
    }

    setFilteredSubCategories(subCategories);
  }, []);

  const handleSearch = (searchValue) => {
    if (searchValue === "") {
      setFilteredSubCategories(subCategories);
      setEmpty(false);
    } else {
      var filtered = subCategories.filter((category) =>
        category?.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      setEmpty(filtered.length === 0);
      setFilteredSubCategories(filtered);
    }
  };

  const handleViewBasket = () => {
    navigate("/cart", { state: { CartData: cart } });
  };

  const handleSubCategoryClick = (subCategory) => {
    dispatch(setSelectedSubCategory(subCategory));
    dispatch(setSubOrder(subCategory.guestOrder));

    var filteredCategories = categories.filter(
      (category) =>
        category.subCategory === subCategory?.id && category.guestView
    );
    filteredCategories = filteredCategories.sort(
      (a, b) => b.precedence - a.precedence
    );

    dispatch(setSelectedCategory(filteredCategories[0]));
    // Navigate to Menu
    navigate("/menuList", {
      state: { Categories: filteredCategories },
    });
  };

  return (
    <div className="Container">
      {isLoading ? (
        <div className="Container centerLoading">
          <MainLoader />
        </div>
      ) : (
        <>
          <div className="NavBarContainer">
            <Navbar items={[]} />
          </div>

          <div className="FixedContainer">
            <div className="SearchContainer">
              {order &&
                order.status !== OrderStatus.delivered &&
                order.status !== OrderStatus.ready &&
                order.status !== OrderStatus.outForDelivery && (
                  <HomeTrackOrder order={order} />
                )}

              {/* <CallWaiterCard /> */}
              <SearchBar onSearch={handleSearch} placeholder={"search"} />
            </div>
          </div>

          {/* List Sub Categories */}
          {empty ? (
            <div className="ContainerSearch">{t("notFoundItem")}</div>
          ) : (
            <div className="AllSections">
              {filteredSubCategories.map((subCategory, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleSubCategoryClick(subCategory)}
                  >
                    <MainCategory
                      categoryName={subCategory.name}
                      categoryImage={
                        subCategory.image ? subCategory.image : null
                      }
                      showImage={false}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {/* Cart Footer Card */}
          {cart && cart?.orderedItems?.length >= 0 && quantity !== 0 && (
            <div className="CartDisplay" onClick={handleViewBasket}>
              <div className="CartDisplayInfo">
                <Typography>
                  <span
                    style={{
                      display: "inline-block",
                      height: "20px",
                      lineHeight: "20px",
                      textAlign: "center",
                      borderRadius: "30%",
                      border: "2px solid #fff",
                      marginRight: "2px",
                      padding: "3px",
                    }}
                  >
                    {quantity}
                  </span>{" "}
                  {t("basket display")}
                </Typography>
                {cart.totalPrice !== 0 && (
                  <Typography>
                    {t("total")} {cart.totalPrice}
                  </Typography>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SubCategories;
