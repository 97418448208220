import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openSnackBar } from "../store/ducks/app";

const useApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function showError(error) {
    if (error.message) {
      return error.message;
    } else if (error.errors && error.errors.length > 0) {
      return error.errors[0].message;
    } else {
      return error;
    }
  }

  return {
    showConfirm: (message) => dispatch(openSnackBar(message, "success")),
    showError: (error) => {
      dispatch(openSnackBar(showError(error), "error"));
    },
    showWarning: (message) => {
      dispatch(openSnackBar(message, "warning"));
    },
    showInfo: (message) => {
      dispatch(openSnackBar(message, "info"));
    },
  };
};

export default useApp;
