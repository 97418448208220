import { useState } from "react";
import "./MainCategory.css";
import { EndPoints } from "../../Constants/Enums";
import { capitalizeSentence } from "../../helpers/utils";

function MainCategory({ categoryName, categoryImage, showImage }) {
  const [imageNotFound, setImageNotFound] = useState(false);

  return (
    <>
      {showImage ? (
        <div className="MainCategoryImageContainer">
          <div className="MainCategoryImage">
            {imageNotFound ? (
              <p>Image Not Found</p>
            ) : (
              <img
                src={`${EndPoints.image}${categoryImage}`}
                alt={categoryName}
                className="MainImage"
                onError={() => setImageNotFound(true)}
              />
            )}
          </div>
          <div className="MainCategoryInfo">
            <p>{capitalizeSentence(categoryName)}</p>
          </div>
        </div>
      ) : (
        <div className="MainCategoryContainer">
          <div className="MainCategoryInfo">
            <p>{capitalizeSentence(categoryName)}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default MainCategory;

// {showImage ? (
//     <div className="MainCategoryImage">
//       {imageNotFound ? (
//         <p>Image Not Found</p>
//       ) : (
//         <img
//           src={`${EndPoints.image}${categoryImage}`}
//           alt={categoryName}
//           className="MainImage"
//           onError={() => setImageNotFound(true)}
//         />
//       )}
//     </div>
//   ) : null}
