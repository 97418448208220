import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { bucketURL } from "../../Constants/Enums";

// Configure the worker URL for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CustomLoadingIndicator = () => <div>Loading PDF... Please wait.</div>;

function PdfView(pdf) {
  const [numPages, setNumPages] = useState(null);
  const [pageHeight, setPageHeight] = useState(window.innerWidth / 1.5); // Initial height, adjust as needed

  const selectedPDF = useSelector((state) => state.subCategories.pdf);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (selectedPDF) {
      setNumPages(null);
    }
  }, [selectedPDF]);

  useEffect(() => {
    const handleResize = () => {
      setPageHeight(window.innerWidth / 1.5);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <Page
          key={i}
          pageNumber={i}
          width={window.innerWidth}
          height={pageHeight}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      );
    }
    return pages;
  };

  return (
    <div>
      {selectedPDF && (
        <Document
          file={`${bucketURL}${selectedPDF}`}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<CustomLoadingIndicator />}
        >
          {renderPages()}
        </Document>
      )}
    </div>
  );
}

export default PdfView;
