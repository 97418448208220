import RestaurantIcon from "@mui/icons-material/Restaurant";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocalDiningIcon from "@mui/icons-material/LocalMall";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { AccountCircle } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import HomeIcon from '@mui/icons-material/Home';


export const items = [
  {
    name: "item1",
    descreption: "item descreption",
    price: "30 AED",
    image: "../../assets/pizza.jpg",
  },
  {
    name: "item1",
    descreption: "item descreption",
    price: "30 AED",
    image: "../../assets/pizza.jpg",
  },
  {
    name: "item1",
    descreption: "item descreption",
    price: "30 AED",
    image: "../../assets/pizza.jpg",
  },
  {
    name: "item1",
    descreption: "item descreption",
    price: "30 AED",
    image: "../../assets/pizza.jpg",
  },
];

const OrderTypeIcons = [
  <RestaurantIcon />,
  <DriveEtaIcon />,
  <LocalShippingIcon />,
  <LocalDiningIcon />,
];
export const OrderTypes = [
  { type: "Dine In", logo: <RestaurantIcon /> },
  { type: "Drive Thru", logo: <DriveEtaIcon /> },
  { type: "Delivery", logo: <LocalShippingIcon /> },
  { type: "Pickup", logo: <LocalDiningIcon /> },
];

export const sampleCategories = [
  "Coffe",
  "Tea",
  "Salads",
  "Beverage",
  "Main Course",
  "Pizza",
  "Burger",
];

export const SideBarTabs = [
  // { name: "Addresses", icon: <HomeIcon />, path: "/address" },
  // { name: "My Cards", icon: <CreditCardIcon />, path: "/mycards" },
  // { name: "My Cars", icon: <DirectionsCarFilledIcon />, path: "mycars" },
  // { name: "Locations", icon: <LocationOnIcon />, path: "ourlocations" },
 // { name: "Reviews", icon: <StarBorderIcon />, path: "/review" },
//  { name: "Languages", icon: <LanguageIcon />, path: "/lang" },
  { name: "Home", icon: <HomeIcon /> ,path:"home"},
  { name: "My orders", icon: <ListAltIcon />, path: "/myorders" },
  { name: "Languages", icon: <LanguageIcon />, path: "" },
  // { name: "Duty Free", icon: <ShoppingBagOutlinedIcon /> },
  // { name: "Order Tracking", icon: <LocalOfferIcon />, path: "/trackOrder" },
];

export const addresses = ["rehab", "maadi", "zayed"];
export const Cards = [
  {
    CardNumber: "1234 **** **** ****",
    ExpirationDate: "12/25",
    CardholderName: "Adham Ibrahim Saber",
    CVV: "123",
  },
  {
    CardNumber: "1234 **** **** ****",
    ExpirationDate: "12/25",
    CardholderName: "Adham Ibrahim Saber",
    CVV: "123",
  },
  {
    CardNumber: "1234 **** **** ****",
    ExpirationDate: "12/25",
    CardholderName: "Adham Ibrahim Saber",
    CVV: "123",
  },
];
export const Cars = ["Car 1", "Car 2", "Car 3"];
export const Locations = ["Location 1", "Location 2"];

export const promoCodes = [
  { title: "First Order", amount: "30 AED", expirydate: "30 October 2023" },
  { title: "30 OFF", amount: "30 AED", expirydate: "30 October 2023" },
];

export const UserData = {
  name: "",
  image: "",
  emptyicon: <AccountCircle style={{ width: 40, height: 40 }} />,
};

export const Cart = {
  conceptID: "",
  userID: "",
  specialRequest: "",
  totalPrice: 150,
  orderedItems: [
    {
      menuItemID: "",
      symphonyID: "",
      name: "",
      quantity: 1,
      choiceIDs: "",
      choiceSymphonyID: "",
      extraChoiceIDs: "",
      extraChoiceSymphonyID: "",
      price: "",
      notes: "",
      image: "",
    },
    {
      menuItemID: "",
      symphonyID: "",
      name: "",
      quantity: 5,
      choiceIDs: "",
      choiceSymphonyID: "",
      extraChoiceIDs: "",
      extraChoiceSymphonyID: "",
      price: "",
      notes: "",
      image: "",
    },
  ],
};

export const mainCategories = [
  {
    name: "All-Day Items",
    image: "AllDay",
    startTime: "06:00",
    endTime: "24:00",
  },
  {
    name: "Breakfast Menu",
    image: "Breakfast",
    startTime: "05:30",
    endTime: "10:30",
  },
  { name: "Lunch Menu", image: "Lunch", startTime: "11:30", endTime: "14:30" },
  {
    name: "Dinner Menu",
    image: "Dinner",
    startTime: "13:30",
    endTime: "22:00",
  },
];

export const url = "https://example.com/?&tableNo=545";
