import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import LanguageIcon from "@mui/icons-material/Language";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "js-cookie";

import "./Languages.css";
import { useSelector } from "react-redux";

const languages = [
  { value: "English", label: "English" },
  { value: "Arabic", label: "Arabic" },
];

function Languages({ type ,currentTarget }) {
  const { t, i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(false);
  const [anchorEl, setAnchorEl] = useState(currentTarget);
  const navigate = useNavigate();
//  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [open, setOpen] = useState(true);
  const encryptedID = useSelector((state) => state.table.encryptedID);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleChange = (language) => {
  //  setSelectedLanguage(language);
    Cookies.set("language", language, { expires: 1 });
    if (language === "Arabic" || language === "he") {
      setIsRTL(true);
    } else {
      setIsRTL(false);
    }
    i18n.changeLanguage(language)//.then(() => {navigate(`/${encryptedID}`);});
    if (language === "Arabic" || language === "he") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
    handleClose();
  };

  // useEffect(() => {
  //   if (selectedLanguage === "Arabic" || selectedLanguage === "he") {
  //     document.documentElement.setAttribute("dir", "rtl");
  //   } else {
  //     document.documentElement.setAttribute("dir", "ltr");
  //   }
  // }, [i18n.language]);

  return (
    <>
      {type && (
        <>
          {/* <IconButton
            onClick={handleOpen}
            sx={{ minWidth: "auto", p: 0, ml: 1 }}
          >
            <LanguageIcon  style={{color:"#AF8E4B"}}/>
          </IconButton> */}

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {languages.map((lang) => (
              <MenuItem
                key={lang.value}
                onClick={() => handleChange(lang.value)}
              >
                {t(lang.label)}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
}

export default Languages;
