// actions
const SET = "table/SET";
const TABLE_ID = "app/TABLE_ID";
const ENCRYPTED_ID = "table/SetEncrypted";

const DEFAULT_STATE = {
  listing: null,
  tableId: null,
  encryptedID: null,
};

// reducer
const reducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case TABLE_ID:
      return Object.assign({}, state, { tableId: action.tableId });
    case ENCRYPTED_ID:
      return Object.assign({}, state, { encryptedID: action.encryptedID });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setTable = (listing) => ({ listing, type: SET });
export const setTableId = (tableId) => ({
  tableId,
  type: TABLE_ID,
});

export const setEncryptedID = (encryptedID) => ({
  encryptedID,
  type: ENCRYPTED_ID,
});
