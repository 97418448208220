import { EndPoints } from '../Constants/Enums';

async function getConcept(conceptID) {
    try {
        const operationId = 11;
        const requestBody = { operationId,conceptID };
        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          };
          const response = await fetch(EndPoints.AppOperationsLambda, options);
          const jsonResponse = await response.json();
          return jsonResponse;
      } catch (error) {
        console.error('Error getting QR Code :', error);
        throw error;
      }
  }

export default getConcept;
