import { EndPoints } from "../Constants/Enums";

async function getCallWaiter(callWaiterId) {
  try {
    const operationId = 15;

    const requestBody = { operationId, callWaiterId };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(EndPoints.AppOperationsLambda, options);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.error("Error getting Call Waiter :", error);
    throw error;
  }
}

export default getCallWaiter;
