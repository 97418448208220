import React from "react";
import "./AllSections.css";
import Section from "../Section/Section";
import { items } from "../../Constants/JsonObjects";


function AllSections({ categories, Cart,handleOpenItem}) {
  return (
    <div className="AllSectionContainer">
      {categories?.map((category) => {
        return (
          <Section
            key={category.id}
            Cart={Cart}
            category={category}
            handleOpenItem={handleOpenItem}
          />
        );
      })}
    </div>
  );
}

export default AllSections;
