import { Typography, Grid } from "@mui/material";
import "./SpaView.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const SpaView = ({ toggleView, onSelect }) => {
  const [list, setList] = useState([]);
  const mainMenuList = [
    { id: 1, name: "Main Menu", pdf: "PDFs/Main+Menu+v2.pdf" },
    { id: 2, name: "Add On Menu", pdf: "PDFs/Add+On+Menu+v2.pdf" },
  ];

  const spaMenuList = [
    { id: 1, name: "Menu and Add On", pdf: "mainMenuList" },
    {
      id: 2,
      name: "Hair and Barber",
      pdf: "PDFs/Hair+and+Barber+v2.pdf",
    },
    { id: 3, name: "Valmont Menu", pdf: "PDFs/Valmont+Menu+v2.pdf" },
  ];

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setList(spaMenuList);
  }, []);

  const openPdfLink = (item) => {
    if (item.pdf.includes("pdf")) {
      onSelect(item);
    } else {
      setList(mainMenuList);
    }
  };

  return (
    <div>
      {
        <div
          className="Open"
          onClick={() => {
            toggleView();
          }}
        />
      }

      <div className="SubCategoriesView">
        <div className="SubCategoryContent">
          <Grid container spacing={"10px"}>
            {list &&
              list.map((item, index) => {
                return (
                  <Grid container item xs={12} key={item.id}>
                    <Grid
                      item
                      xs={12}
                      onClick={() => {
                        openPdfLink(item);
                      }}
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        justifyItems={"center"}
                        alignContent={"center"}
                        alignItems={"center"}
                        item
                        xs={12}
                        style={{
                          width: "100%",
                          height: 80,
                          background:
                            "linear-gradient(135deg, white 0%, #ECE7DF 52%, #EAE6DD 56%, #FCF3DE 100%)",
                          borderRadius: 10,
                          border: "2px #D2B56F solid",
                        }}
                      >
                        <Grid
                          container
                          direction={"row"}
                          item
                          xs={12}
                          justifyContent={"center"}
                          justifyItems={"center"}
                          alignContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography
                            style={{
                              color: "black",
                              fontSize: 25,
                              fontFamily: `etihadBook${i18n.language}`,
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SpaView;
