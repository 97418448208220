import React from 'react'
import './MainLoader.css'
function MainLoader() {
  return (
<div className="loader">
  <div className="box"></div>
  <div className="box"></div>
  <div className="box"></div>
  <div className="box"></div>
</div>

  )
}

export default MainLoader