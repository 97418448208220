import { Link, useLocation, useNavigate } from "react-router-dom";
import "./OrderTracking.css";
import UpdateIcon from "@mui/icons-material/Update";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { IconButton, Rating ,Snackbar} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { API } from "aws-amplify";
import { onUpdateOnlineOrder } from "../../graphql/subscriptions";
import { useEffect, useState } from "react";
import { OrderStatus } from "../../Constants/Enums";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { formatDate, formatTime } from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentOrder } from "../../store/ducks/app";
import cancelOnlineOrder from "../../Services/cancelOnlineOrder.js";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import Navbar from "../../Components/NavBar/NavBar";
import { numberTranslation } from "../../helpers/utils";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function OrderTracking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const canReview = JSON.parse(Cookies.get("userCookie")).canReview;
  const order = location.state.Order;
  const myOrders = location.state.BackTo;
  const [newOrder, setNewOrder] = useState(order);
  const [pending, setPending] = useState(true);
  const [placed, setPlaced] = useState(false);
  const [sentToKitchen, setSentToKitchen] = useState(false);
  const [allReady, setAllReady] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);



  const encryptedID = useSelector((state) => state.table.encryptedID);

  function updateTrack(order) {
    switch (order.status) {
      case OrderStatus.pending: {
        setPending(true);
        setPlaced(false);
        setSentToKitchen(false);
        setDelivered(false);
        break;
      }
      case OrderStatus.acceptedByWaiter: {
        setPending(true);
        setPlaced(true);
        setSentToKitchen(false);
        setDelivered(false);
        break;
      }
      case OrderStatus.sentToKitchen: {
        setPending(true);
        setPlaced(true);
        setSentToKitchen(true);
        setDelivered(false);
        break;
      }
      case OrderStatus.allReady : {
        setPending(true);
        setPlaced(true);
        setSentToKitchen(true);
        setAllReady(true)
        setDelivered(false);
        break;
      }
      case OrderStatus.delivered : {
        setPending(true);
        setPlaced(true);
        setSentToKitchen(true);
        setAllReady(true)
        setDelivered(true);
        break;
      }
      case OrderStatus.cancelled : {
        setPending(true);
        setCancelled(true)
        setPlaced(false);
        setSentToKitchen(false);
        setAllReady(false)
        setDelivered(false);
        break;
      }
      default: {
        break;
      }
    }
  }

  useEffect(() => {
    const currentOrder = Cookies.get("trackedOrder")
    ? JSON.parse(Cookies.get("trackedOrder"))
    : null;
    
    setNewOrder(currentOrder);
    updateTrack(currentOrder);
    const subscription = API.graphql({ query: onUpdateOnlineOrder });

    if ("subscribe" in subscription) {
      const orderSubscription = subscription.subscribe({
        next: (event) => {
          const updatedOrder = event.value.data.onUpdateOnlineOrder;

          if (updatedOrder.id === currentOrder.id) {
            Cookies.set("trackedOrder", JSON.stringify(updatedOrder));
            dispatch(setCurrentOrder(updatedOrder));
            setNewOrder(updatedOrder);
            updateTrack(updatedOrder);
          }
        },
        error: (error) => {
          console.error("Subscription error:", error);
        },
      });

      return () => {
        if ("unsubscribe" in orderSubscription) {
          orderSubscription.unsubscribe();
        }
      };
    }

  }, []);

  const handleShowClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
  };

  const handleCancel= async()=>{
    if(newOrder.status===OrderStatus.pending)
    {
      setLoading(true)
      await cancelOnlineOrder(newOrder);
      setShow(true)
      setSuccess(true)
      setLoading(false)

    }
    else
    {
      setShow(true)
      setSuccess(false)
    }
 
  }
  return (
    <>
      <div className="TrackingContainer" style={{background:"white"}}>
      <div className="NavBarContainer">
            <Navbar items={[]} />
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
          <IconButton aria-label="menu" style={{rotate:i18n.language==="Arabic"?"180deg":"0deg"}}
          onClick={()=>{ navigate(`/${encryptedID}`)}}>
            <ArrowBack style={{color:"#AF8E4B"}} />
          </IconButton>
        </div>
        {/* Header */}
        <div className="OrderTrackingHeader">
          <span className="header">
          <h2  style={{fontFamily:`${i18n.language}"etihadBook"`}}>{t("orderTrack")}</h2>
          {/* <span
            style={{
              lineHeight: "normal",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "600",
              letterSpacing: "1.16px",
              textAlign: "center",
            }}
          >
            {formatDate(new Date(newOrder.createdAt))}
          </span> */}
          <span
            style={{
              fontSize: "15px",
              fontWeight: "600",
              letterSpacing: "0.04em",
              textAlign: "center",
              marginTop:".5rem",
             fontFamily:`${i18n.language}"etihadBook"`
            }}
          >
            {t("OrderID")} : #{newOrder.id.split("-")[0]}
          </span>
          </span>
          <span style={{ alignContent:"center" ,marginLeft:"auto",marginRight:"auto"}}>
       {newOrder.status===OrderStatus.pending&&( 
       <button disabled={loading} style={{color:loading?"":"#c5941b",fontFamily:`${i18n.language}"etihadBook"`}}  className="cancelButton" onClick={handleCancel}>
          {t("Cancel")}
        </button>)}
        </span>
        </div>

        {/* List of Statuses */}
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "98%",
          }}
        >
          <Stack spacing={2} sx={{ width: "100%" }}>
            {pending && (
              <>
                <Item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <UpdateIcon sx={{ color: "#997408" }} fontSize="large" />
                  <div className="OrderTrackingItem">
                    <span style={{ color: "black" ,fontFamily:`${i18n.language}"etihadBook"`}}>{t("PendingOrder")}</span>

                    <span style={{ color: "#9D9D9D", fontSize: "12px" ,fontFamily:`${i18n.language}"etihadBook"` }}>
                      {t("orderPending")} 
                    </span>
                  </div>
                  <span style={{ fontWeight: "600", color: "black" }}>
                  {numberTranslation(formatTime(new Date(newOrder.createdAt)),i18n.language)}
                  </span>
                </Item>
                <MoreVertIcon sx={{ color: "#D9D9D9", fontSize: "2.5rem" }} />
              </>
            )}
             {cancelled && (
              <>
                <Item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RoomServiceOutlinedIcon
                    sx={{ color: "#997408" }}
                    fontSize="large"
                  />
                  <div className="OrderTrackingItem">
                    <span style={{ color: "black",fontFamily:`${i18n.language}"etihadBook"` }}>{t("cancelledOrder")}</span>

                    <span style={{ color: "#9D9D9D", fontSize: "12px",fontFamily:`${i18n.language}"etihadBook"` }}>
                      {t("cancelledOrder")}
                    </span>
                  </div>
                  <span style={{ fontWeight: "bold", color: "black" }}>
                  {numberTranslation(formatTime(new Date(newOrder.createdAt)),i18n.language)}
                  </span>
                </Item>
              </>
            )}
            {placed && (
              <>
                <Item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RoomServiceOutlinedIcon
                    sx={{ color: "#997408" }}
                    fontSize="large"
                  />
                  <div className="OrderTrackingItem">
                    <span style={{ color: "black",fontFamily:`${i18n.language}"etihadBook"`}}>{t("PlacedOrder")}</span>

                    <span style={{ color: "#9D9D9D", fontSize: "12px",fontFamily:`${i18n.language}"etihadBook"`}}>
                      {t("orderProcessed")} 
                    </span>
                  </div>
                  <span style={{ fontWeight: "bold", color: "black" }}>
                  {numberTranslation(formatTime(new Date(newOrder.acceptedByWaiterAt)),i18n.language)}
                  </span>
                </Item>
                <MoreVertIcon sx={{ color: "#D9D9D9", fontSize: "2.5rem" }} />
              </>
            )}
             {sentToKitchen && (
              <>
                <Item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RoomServiceOutlinedIcon
                    sx={{ color: "#997408" }}
                    fontSize="large"
                  />
                  <div className="OrderTrackingItem">
                    <span style={{ color: "black",fontFamily:`${i18n.language}"etihadBook"` }}>{t("PreparingOrder")}</span>

                    <span style={{ color: "#9D9D9D", fontSize: "12px",fontFamily:`${i18n.language}"etihadBook"` }}>
                      {t("orderPreparing")}
                    </span>
                  </div>
                  <span style={{ fontWeight: "bold", color: "black" }}>
                  {numberTranslation(formatTime(new Date(newOrder.sentToKitchenAt)),i18n.language)}
                  </span>
                </Item>
                <MoreVertIcon sx={{ color: "#D9D9D9", fontSize: "2.5rem" }} />
              </>
            )}
             {allReady && (
              <>
                <Item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RoomServiceOutlinedIcon
                    sx={{ color: "#997408" }}
                    fontSize="large"
                  />
                  <div className="OrderTrackingItem">
                    <span style={{ color: "black",fontFamily:`${i18n.language}"etihadBook"` }}>{t("RaadyOrder")}</span>

                    <span style={{ color: "#9D9D9D", fontSize: "12px" ,fontFamily:`${i18n.language}"etihadBook"`}}>
                      {t("orderReady")}
                    </span>
                  </div>
                  <span style={{ fontWeight: "bold", color: "black" }}>
                  {numberTranslation(formatTime(new Date(newOrder.readyAt)),i18n.language)}
                  </span>
                </Item>
                <MoreVertIcon sx={{ color: "#D9D9D9", fontSize: "2.5rem" }} />
              </>
            )}
            {delivered && (
              <>
                <Item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RestaurantOutlinedIcon
                    sx={{ color: "#997408" }}
                    fontSize="medium"
                  />
                  <div className="OrderTrackingItem">
                    <span style={{ color: "black" ,fontFamily:`${i18n.language}"etihadBook"`}}>
                      {t("DeliveredOrder")}
                    </span>

                    <span style={{ color: "#9D9D9D", fontSize: "12px",fontFamily:`${i18n.language}"etihadBook"` }}>
                      {t("orderReceived")}
                    </span>
                  </div>
                  <span style={{ fontWeight: "bold", color: "black" }}>
                  {numberTranslation(formatTime(new Date(newOrder.deliveredAt)),i18n.language)}
                  </span>
                </Item>
              </>
            )}
          </Stack>
        </div>
        {/* {delivered && canReview && (
          <Item
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "1rem",
              alignItems: "center",
              marginTop: "3rem",
              width: "94%",
            }}
          >
            <StarBorderIcon sx={{ color: "#997408" }} fontSize="large" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <span style={{ color: "black" }}>{t("RateExperience")}</span>

              <span>{t("loveToRateExperience")}</span>
              <Rating name="no-value" size="small" />
            </div>
          </Item>
        )} */}
        <div>
         <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={show}
              autoHideDuration={3000}
              onClose={handleShowClose}
            >
              <Alert
                onClose={handleShowClose}
                severity={success?"success":"error"}
                sx={{
                  position: "fixed",
                  top: "16px",
                  right: "16px",
                  fontFamily:`${i18n.language}"etihadBook"`
                }}
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleShowClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                {success?t("cancelledOrder"):t("cancelFail")}
              </Alert>
            </Snackbar>
            </div>
      </div>
    </>
  );
}
