// actions
const SET = "mainCategories/SET";
const SET_SELECTED = "mainCategories/SET_SELECTED";
const PDF = "mainCategories/PDF";

const DEFAULT_STATE = {
  selectedMainCategory: null,
  listing: [],
  pdf: null,
};

// reducer
const reducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, {
        selectedMainCategory: action.selectedMainCategory,
      });
    case PDF:
      return Object.assign({}, state, { pdf: action.pdf });

    default:
      return state;
  }
};

export default reducer;

// action creators
export const setMainCategories = (listing) => ({ listing, type: SET });
export const setSelectedMainCategoryTab = (selectedMainCategory) => ({
  selectedMainCategory,
  type: SET_SELECTED,
});
export const setPDF = (pdf) => ({ pdf, type: PDF });
