import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Typography, Grid, Box } from "@mui/material";
import "./SubCategoriesView.css";
import { useSelector } from "react-redux";
import { capitalizeSentence, getTimeInUAE } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import { bucketURL } from "../../Constants/Enums";

const SubCategoriesView = ({ toggleView, onSelect, selectedMainCategory }) => {
  const { t, i18n } = useTranslation();

  const subCategories = useSelector((state) => state.subCategories.listing);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  function isCategoryAvailable(category) {
    const isOpenAllDay =
      category.openTime === "00:00" && category.closeTime === "00:00";
    if (isOpenAllDay) {
      return true;
    }

    const now = moment().tz("Asia/Dubai");
    // const now = moment()
    //   .tz("Asia/Dubai")
    //   .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    // Define startTime and endTime
    let startTime = moment(
      now.format("YYYY-MM-DD") + " " + category.openTime,
      "YYYY-MM-DD HH:mm"
    ).tz("Asia/Dubai", true);
    let endTime = moment(
      now.format("YYYY-MM-DD") + " " + category.closeTime,
      "YYYY-MM-DD HH:mm"
    ).tz("Asia/Dubai", true);

    // Adjust for the special case where both times are "00:00"
    let isOpenNow;
    if (endTime.isBefore(startTime)) {
      // Check if current time is after startTime or before endTime (spanning midnight)
      isOpenNow = now.isAfter(startTime) || now.isBefore(endTime);
    } else {
      // Standard case
      isOpenNow = now.isBetween(startTime, endTime);
    }

    // console.log({
    //   name: category.name,
    //   now: now.format("dddd MMM DD YYYY HH:mm:ss"),
    //   startTime: startTime.format("dddd MMM DD YYYY HH:mm:ss"),
    //   endTime: endTime.format("dddd MMM DD YYYY HH:mm:ss"),
    //   isOpenNow,
    // });

    return isOpenNow;
  }

  useEffect(() => {
    // let interval = null;
    // interval = setInterval(() => {
    //   let newList = list.filter((item) => {
    //     return item.guestView && isCategoryAvailable(item);
    //   });
    //   if (!(JSON.stringify(newList) === JSON.stringify(list))) {
    //     setList(
    //       newList.sort((a, b) => {
    //         return b.precedence - a.precedence;
    //       })
    //     );
    //   }
    // }, 1000);

    // return () => {
    //   clearInterval(interval);
    // };
    prepareSubCategories();
  }, []); // list

  const prepareSubCategories = () => {
    setLoading(true);
    let newList = subCategories.filter((item) => {
      return (
        item.guestView &&
        isCategoryAvailable(item) &&
        selectedMainCategory.id === item.mainCategoryID
      );
    });
    setList(newList);
    setLoading(false);
  };

  const getSubCategoryName = (item) => {
    let name =
      new Map(JSON.parse(item.multiLanguages))?.get(`${i18n.language}-name`) ??
      item.name;

    // Split the name by "-"
    const nameParts = name.split("-");

    // If there are two parts, return the second part. Otherwise, return the full name.
    name = nameParts.length > 1 ? nameParts[1] : name;

    return capitalizeSentence(name);
  };

  return (
    <div>
      {
        <div
          className="Open"
          onClick={() => {
            toggleView();
          }}
        />
      }

      <div className="SubCategoriesView">
        <div className="SubCategoryContent">
          <Grid container spacing={"10px"}>
            {!loading &&
              list
                // .filter((item) => {
                //   return (
                //     selectedMainCategory.id === item.mainCategoryID &&
                //     item.guestView &&
                //     isCategoryAvailable(item)
                //   );
                // })
                .map((item, index) => {
                  return (
                    <Grid container item xs={12} key={item.id}>
                      <Grid
                        item
                        xs={12}
                        onClick={() => {
                          onSelect(item);
                        }}
                      >
                        <Grid
                          container
                          justifyContent={"center"}
                          justifyItems={"center"}
                          alignContent={"center"}
                          alignItems={"center"}
                          item
                          xs={12}
                          style={{
                            width: "100%",
                            height: 80,
                            background:
                              "linear-gradient(135deg, white 0%, #ECE7DF 52%, #EAE6DD 56%, #FCF3DE 100%)",
                            borderRadius: 10,
                            border: "2px #D2B56F solid",
                          }}
                        >
                          <Grid
                            container
                            direction={"row"}
                            item
                            xs={12}
                            justifyContent={"center"}
                            justifyItems={"center"}
                            alignContent={"center"}
                            alignItems={"center"}
                          >
                            {item.image && (
                              <>
                                <Box
                                  component="img"
                                  src={`${bucketURL}${item.image}`}
                                  sx={{
                                    width: "30px",
                                    height: "30px",
                                    transition: "opacity 400ms ease 0ms",
                                    paddingLeft: ".5rem",
                                    paddingRight: ".5rem",
                                  }}
                                />
                              </>
                            )}
                            <Typography
                              style={{
                                color: "black",
                                fontSize: 25,
                                fontFamily: `etihadBook${i18n.language}`,
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              {/* Show sub category name "Capitalize" */}
                              {getSubCategoryName(item)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                color: "black",
                                fontSize: 15,
                                fontFamily: `etihadBook${i18n.language}`,
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              {capitalizeSentence(
                                new Map(JSON.parse(item.multiLanguages))?.get(
                                  `${i18n.language}-${"subName"}`
                                ) ??
                                  item.subName ??
                                  ""
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SubCategoriesView;
