import { EndPoints } from '../Constants/Enums';

async function createReview(reason, value , createdAt) {
  try {
    const operationId = 9;
    const requestBody = { operationId, reason, value ,createdAt };
    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      };
      const response = await fetch(EndPoints.AppOperationsLambda, options);
      const jsonResponse = await response.json();
      return jsonResponse;
  } catch (error) {
    console.error('Error submitting review :', error);
    throw error;
  }
}

export default createReview;
