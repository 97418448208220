import { EndPoints } from '../Constants/Enums';

async function getSubCategories() {
  try {
    const operationId = 8;
    const requestBody = { operationId };
    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      };
      const response = await fetch(EndPoints.AppOperationsLambda, options);
      const jsonResponse = await response.json();
      return jsonResponse;
  } catch (error) {
    console.error('Error getting Sub Categories :', error);
    throw error;
  }
}

export default getSubCategories;
