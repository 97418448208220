import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/NavBar/NavBar";
import { useState } from "react";
import createReview from "../../Services/createReview";
import {
  Alert,
  IconButton,
  InputAdornment,
  Rating,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import RateReviewIcon from "@mui/icons-material/RateReview";
import "./Review.css";
import Cookies from "js-cookie";
import DialogAlert from "../../Components/Dialog/DialogAlert";
import { useSelector } from "react-redux";
import { getTimeInUAE } from "../../helpers/utils";


const Review = ({ handleDisable }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [review, setReview] = useState("");
  const [value, setValue] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [open, setOpen] = useState(false);
  const encryptedID = useSelector((state) => state.table.encryptedID);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const submitReview = async () => {
    if (!review || review.length === 0) {
      setDialogTitle("Missing Review");
      setOpenDialog(true);
    } else if (value === 0) {
      setDialogTitle("Missing Rating");
      setOpenDialog(true);
    } else {
      setDisabled(true);
      const submit = await createReview(review, value ,getTimeInUAE());
      if (submit) {
        handleOpen();
        const cookieData = {
          uid: JSON.parse(Cookies.get("userCookie")).uid, // Example UID
          hasCart: JSON.parse(Cookies.get("userCookie")).hasCart, // Example username
          canReview: false,
        };
        Cookies.set("userCookie", cookieData);
        navigate(`/${encryptedID ? encryptedID : ""}`);

        // setTimeout(() => {
        //   navigate(`/${encryptedID ? encryptedID : ""}`);
        // }, 1500);
      }
      // handleDisable()
    }
  };

  const closeReviewForm = () => {
    navigate(`/${encryptedID ? encryptedID : ""}`);
  };

  return (
    <>
      <Navbar />
      {openDialog && (
        <DialogAlert
          handleClose={() => setOpenDialog(false)}
          open={openDialog}
          text={t(
            `Please fill out the ${
              dialogTitle === "Missing Review" ? "review" : "rating"
            } before submitting. Thank you for your feedback!`
          )}
          title={dialogTitle}
        />
      )}

      <div className="ReviewBody">
        <p className="Headline">
          {t("reviewHeader")}
          <Rating
            style={{ color: "#c5941b", fontSize: "2.1rem" }}
            name="no-value"
            value={parseFloat(value)}
            onChange={(event) => setValue(event.target.value)}
            sx={{ marginTop: "1rem" }}
          />
        </p>
        <div className="ReviewForm">
          <TextField
            multiline
            placeholder={t("Share your feedback")}
            style={{ borderRadius: "50px", marginTop: "0rem" }}
            value={review}
            onChange={(event) => setReview(event.target.value)}
            InputProps={{
              style: {
                height: "50vh",
                width: "80vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "row",
                alignContent: "center",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <RateReviewIcon
                    sx={{ marginTop: "1.5rem", marginRight: "1rem" }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{
                position: "fixed",
                top: "16px",
                right: "16px",
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {t("reviewSuccess")}
            </Alert>
          </Snackbar>
        </div>
        <div className="FixedCart">
          <div className="FixedInfo">
            <button className="HalfButton" onClick={closeReviewForm}>
              <Typography style={{ color: "#C5941B " }}>
                {t("Cancel")}
              </Typography>
            </button>
            <button
              className={`HalfButton2`}
              onClick={submitReview}
              disabled={disabled}
            >
              <Typography style={{ color: "white" }}>{t("Submit")}</Typography>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
