import React, { useState } from "react";
import "./Choices.css";
import { Typography, Checkbox, FormControlLabel, Grid } from "@mui/material"; // Import Checkbox from your preferred library
import { useTranslation } from "react-i18next";
import { capitalizeSentence, numberTranslation } from "../../helpers/utils";

function Choises({ options, onSelectedChoices, choiceGroup }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { t, i18n } = useTranslation();

  const handleOptionChange = (option) => {
    const optionPrice = option.price || 0; // Get the price of the selected option
    const optionId = option.id; // Get the ID of the selected option (modify this according to your data structure)
    let newSelectedOptions = [];
    if (selectedOptions.includes(option.name)) {
      newSelectedOptions = selectedOptions.filter(
        (item) => item !== option.name
      );
    } else {
      if (selectedOptions.length < choiceGroup.maxNumberOfChoices) {
        newSelectedOptions = [...selectedOptions, option.name];
      }
    }
    setSelectedOptions(newSelectedOptions);

    const selectedChoicesPrice = newSelectedOptions.reduce(
      (totalPrice, selectedOption) => {
        const option = options.find((opt) => opt.name === selectedOption);
        return totalPrice + (option ? option.price : 0);
      },
      0
    );

    // Pass both price and ID to the onSelectedChoices function
    // pass name also
    onSelectedChoices(
      selectedChoicesPrice,
      optionId,
      newSelectedOptions,
      option.name,
      option.multiLanguages
    );
  };

  return (
    <>
      <div className="ChoiceInfo">
        <div className="ChoiceInfo">
          <Typography
            style={{
              color: "black",
              fontSize: 18,
              fontFamily: `etihadBook${i18n.language}`,
              fontWeight: "500",
              wordWrap: "break-word",
              textAlign: "start",
            }}
          >
            {capitalizeSentence(
              new Map(JSON.parse(choiceGroup.multiLanguages))?.get(
                `${i18n.language}-${"name"}`
              ) ?? choiceGroup.name
            )}
            <span style={{ color: "red" }}>
              {choiceGroup.required ? " *" : ""}
            </span>
          </Typography>
          <Typography
            style={{
              color: "#919191",
              fontSize: 13,
              fontFamily: `etihadBook${i18n.language}`,
              fontWeight: "400",
              marginRight: "1rem",
              marginLeft: "1rem",
              wordWrap: "break-word",
            }}
          >
            {`${t("Min")} ${numberTranslation(
              choiceGroup.minNumberOfChoices,
              i18n.language
            )}, ${t("Max")} ${numberTranslation(
              choiceGroup.maxNumberOfChoices,
              i18n.language
            )}`}
          </Typography>
        </div>
        <div
          style={{
            height: "100%",
            background: "#393744",
            borderRadius: 14,
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: 13,
              fontFamily: `etihadBook${i18n.language}`,
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            {choiceGroup.required ? t("Required") : t("Optional")}
          </Typography>
        </div>
      </div>

      <div className="card2">
        {options.map((choice, index) => (
          <Grid
            container
            item
            key={choice.id}
            sx={{ p: "0", marginBottom: "-5px" }}
          >
            <Grid
              item
              xs={8}
              container
              alignContent={"center"}
              alignItems={"center"}
              sx={{ marginBottom: "-5px" }}
            >
              <Typography
                align="left"
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  fontFamily: `etihadBook${i18n.language}`,
                  color: "#919191",
                }}
              >
                {capitalizeSentence(
                  new Map(JSON.parse(choice.multiLanguages))?.get(
                    `${i18n.language}-${"name"}`
                  ) ?? choice.name
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              alignContent={"center"}
              alignItems={"center"}
              sx={{ marginBottom: "-5px" }}
            >
              {choice.price !== 0 && (
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    fontFamily: `etihadBook${i18n.language}`,
                    color: "#919191",
                    display: "inline-block",
                  }}
                >
                  {" "}
                  {"+" + "(" + choice.price + " AED" + ")"}{" "}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              container
              justifyContent={"end"}
              sx={{ marginBottom: "-5px" }}
            >
              <Checkbox
                size="small"
                sx={{ "&.Mui-checked": { color: "#C19A5D" } }}
                checked={selectedOptions.includes(choice.name)}
                onChange={() => handleOptionChange(choice)}
                disabled={
                  selectedOptions.length >= choiceGroup.maxNumberOfChoices &&
                  !selectedOptions.includes(choice.name)
                }
              />
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );
}

export default Choises;
